import { CURRENT_YEAR } from '@global/constants/shared';
import { SearchFiltersValues } from './types';

export const DEFAULT_SEARCH_FILTERS: SearchFiltersValues = {
  filterType: 'Worldwide',
  query: '',
  isRegularSearch: false,
  scoreFrom: 65,
  companySizes: [],
  countries: [],
  founded: [1900, CURRENT_YEAR],
  funding: false,
  foundingStages: [],
  tags: [],
  companyType: null,
  targetMarkets: [],
  revenue: [0, 1_000_000_000],
  EBITDA: [0, 1_000_000_000],
  NFP: [0, 1_000_000_000],
  numberOfShareholders: [1, 1_000],
  shareholderAge: [20, 99],
  italianRegions: [],
  EBITDAMargin: [10, 100],
};
