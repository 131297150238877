import { DEFAULT_SEARCH_FILTERS, useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard, RangeFilter } from '../common';

export const NumberOfShareholdersFilter: FC = () => {
  const numberOfShareholders = useSearchFiltersStore((state) => state.numberOfShareholders);
  const setNumberOfShareholders = useSearchFiltersStore((state) => state.actions.setNumberOfShareholders);

  return (
    <FilterCard label={{ text: 'numberOfShareholders' }}>
      <RangeFilter
        value={numberOfShareholders}
        onChangeFirstValue={(value) => setNumberOfShareholders([value, numberOfShareholders[1]])}
        onChangeSecondValue={(value) => setNumberOfShareholders([numberOfShareholders[0], value])}
        limits={[DEFAULT_SEARCH_FILTERS.numberOfShareholders[0], DEFAULT_SEARCH_FILTERS.numberOfShareholders[1]]}
        step={100}
        mask={{
          alias: 'integer',
          min: DEFAULT_SEARCH_FILTERS.numberOfShareholders[0],
          max: DEFAULT_SEARCH_FILTERS.numberOfShareholders[1],
        }}
      />
    </FilterCard>
  );
};
