import { LocalizedText, Typography } from '@components/Typography';
import { FC } from 'react';

type FinancialInsightsTableHeaderCellProps = {
  label: LocalizedText;
  details?: LocalizedText;
};

export const FinancialInsightsTableHeaderCell: FC<FinancialInsightsTableHeaderCellProps> = ({ label, details }) => {
  return (
    <div className='flex flex-col *:text-grey-700'>
      <Typography {...label} variant="sm/bold" className="uppercase" />
      {details ? <Typography {...details} variant="sm/regular" className='normal-case' /> : null}
    </div>
  );
};
