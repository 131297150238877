import { useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { SearchFilterTypeButton } from './SearchFilterTypeButton';

export const SearchFilterTypesBar: FC = () => {
  const filterType = useSearchFiltersStore((state) => state.filterType);
  const setFilterType = useSearchFiltersStore((state) => state.actions.setFilterType);

  return (
    <div className="flex gap-3">
      <SearchFilterTypeButton
        label={{ text: 'italy' }}
        isActive={filterType === 'Italy'}
        onClick={() => setFilterType('Italy')}
        description={{ text: 'financialDataAvailable' }}
      />
      <SearchFilterTypeButton
        label={{ text: 'worldwide' }}
        isActive={filterType === 'Worldwide'}
        onClick={() => setFilterType('Worldwide')}
      />
    </div>
  );
};
