import { COMPANY_SIZE_OPTIONS } from '@/data/options';
import { MultiSelect } from '@components/MultiSelect';
import { useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard } from '../common';

export const CompanySizeFilter: FC = () => {
  const companySizes = useSearchFiltersStore((state) => state.companySizes);
  const toggleCompanySize = useSearchFiltersStore((state) => state.actions.toggleCompanySize);

  return (
    <FilterCard label={{ text: 'companySize' }}>
      <MultiSelect
        options={COMPANY_SIZE_OPTIONS}
        selectedItems={companySizes}
        handleToggleItem={toggleCompanySize}
        inputClassName={undefined}
        optionLabelClassName={undefined}
        isSearchable={undefined}
      />
    </FilterCard>
  );
};
