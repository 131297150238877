import { SearchFilters, SearchFilterTypesBar, SearchQuery } from '@components/SearchFilters';
import { SvgIcon } from '@components/SvgIcon';
import { Tooltip } from '@components/Tooltip';
import { useSearchFiltersStore } from '@global/searchFilters';
import { IoFilterSharp } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';
import useSearchForm from '../hooks/useSearchForm';

function Home() {
  const { isQueryValid, go, inputError, showFilters, setShowFilters } = useSearchForm(false, '/search', '/search');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
    if (event.key === 'Enter' && isQueryValid()) {
      event.preventDefault();
      go(event);
    }
  };

  const isRegularSearch = useSearchFiltersStore((state) => state.isRegularSearch);
  const handleChangeIsRegularSearch = useSearchFiltersStore((state) => state.actions.setIsRegularSearch);

  return (
    <div className="card-elevation flex min-h-[80vh] w-full flex-col items-center justify-start pb-5 pt-20">
      <div className="flex w-[300px] flex-col items-center justify-center gap-4 lg:w-[850px]">
        {/* Title */}
        <h2 className="text-3xl font-bold lg:text-5xl">Welcome to YunoAI</h2>
        <p className="px-5 pb-2 text-center text-sm lg:px-0 lg:text-lg">
          Find, understand & track the companies that matter to you
        </p>
        <SearchFilterTypesBar />

        {/* Form */}
        <form className="flex w-full flex-col" onSubmit={go}>
          {/* Query */}
          <div className="flex flex-col items-start justify-center px-2">
            <p className="mb-4 ml-3 font-primary font-bold lg:text-xl">Company Search</p>
            <SearchQuery
              iconSize={20}
              inputError={inputError}
              isQueryValid={isQueryValid}
              onKeyDown={handleKeyDown}
              wrapperClassName="border border-[#7D8FB3] lg:py-8 text-lg "
            >
              <button
                type="button"
                onClick={() => setShowFilters(!showFilters)}
                className="btn btn-circle tooltip tooltip-primary flex items-center justify-center rounded-full border border-gray-200 bg-base-200 shadow-md active:shadow-inner hover:bg-base-200"
                data-tip="Filters"
              >
                <IoFilterSharp size={20} />
              </button>
            </SearchQuery>

            {/* GenAI */}
            <div className="justify-left flex items-center gap-2 py-4">
              <span className="text-md font-semibold">AI Search</span>
              <div className="form-control">
                <label className="label flex w-fit cursor-pointer gap-4">
                  <input
                    type="checkbox"
                    className={`toggle ${
                      isRegularSearch
                        ? 'border-neutral bg-neutral hover:bg-neutral hover:opacity-80'
                        : 'border-primary-600 bg-primary-600 hover:bg-primary-600 hover:opacity-80'
                    }`}
                    onChange={(e) => handleChangeIsRegularSearch(e.target.checked)}
                    checked={isRegularSearch}
                    data-label="type"
                  />
                </label>
              </div>
              <span className="font-regular text-md">Search by name</span>
              <Tooltip content="nameAndAISearchesTooltip" side="right" align="start">
                <SvgIcon name="HelpCircleGradientIcon" className="size-6" />
              </Tooltip>
            </div>
          </div>
          <SearchFilters isVisible={showFilters} className={twMerge('grid-cols-2 lg:grid-cols-4')} />
        </form>
      </div>
    </div>
  );
}

export default Home;
