import { REGION_OPTIONS } from '@/data/options';
import { MultiSelect } from '@components/MultiSelect';
import { useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard } from '../common';

export const RegionFilter: FC = () => {
  const countries = useSearchFiltersStore((state) => state.countries);
  const toggleCountry = useSearchFiltersStore((state) => state.actions.toggleCountry);

  return (
    <FilterCard label={{ text: 'region' }}>
      <MultiSelect
        options={REGION_OPTIONS}
        selectedItems={countries}
        handleToggleItem={toggleCountry}
        isSearchable
        inputClassName={undefined}
        optionLabelClassName={undefined}
      />
    </FilterCard>
  );
};
