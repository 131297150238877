import { COMPANY_TYPE_OPTIONS } from '@/data/options';
import { NestedSelect } from '@components/NestedSelect';
import { useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard } from '../common';

export const EntityTypeFilter: FC = () => {
  const companyType = useSearchFiltersStore((state) => state.companyType);
  const setCompanyType = useSearchFiltersStore((state) => state.actions.setCompanyType);

  return (
    <FilterCard label={{ text: 'entityType' }}>
      <NestedSelect options={COMPANY_TYPE_OPTIONS} selectedOption={companyType} handleChange={setCompanyType} />
    </FilterCard>
  );
};
