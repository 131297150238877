import { LATEST_FUNDING_TYPE_OPTIONS } from '@/data/options';
import { MultiSelect } from '@components/MultiSelect';
import { useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard } from '../common';

export const LatestFundingFilter: FC = () => {
  const foundingStages = useSearchFiltersStore((state) => state.foundingStages);
  const toggleFoundingStage = useSearchFiltersStore((state) => state.actions.toggleFoundingStage);

  return (
    <FilterCard label={{ text: 'latestFunding' }}>
      <MultiSelect
        options={LATEST_FUNDING_TYPE_OPTIONS}
        selectedItems={foundingStages}
        handleToggleItem={toggleFoundingStage}
        inputClassName={undefined}
        optionLabelClassName={undefined}
        isSearchable={undefined}
      />
    </FilterCard>
  );
};
