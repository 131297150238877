import { TARGET_MARKETS_OPTIONS } from '@/data/options';
import { MultiSelect } from '@components/MultiSelect';
import { useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard } from '../common';

export const TargetMarketsFilter: FC = () => {
  const targetMarkets = useSearchFiltersStore((state) => state.targetMarkets);
  const toggleMarket = useSearchFiltersStore((state) => state.actions.toggleMarket);

  return (
    <FilterCard label={{ text: 'markets' }}>
      <MultiSelect
        options={TARGET_MARKETS_OPTIONS}
        selectedItems={targetMarkets}
        handleToggleItem={toggleMarket}
        inputClassName={undefined}
        optionLabelClassName={undefined}
        isSearchable={undefined}
      />
    </FilterCard>
  );
};
