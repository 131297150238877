import { Input } from '@components/Input';
import { Slider, SliderOwnProps } from '@mui/material';
import { ComponentProps, FC, useCallback } from 'react';
import { COMMON_INPUT_PROPS } from './constants';

type RangeFilterProps = {
  value: [number, number];
  onChangeFirstValue: (value: number) => void;
  onChangeSecondValue: (value: number) => void;
  limits: [number, number];
  step?: number;
  mask?: ComponentProps<typeof Input>['mask'];
};

export const RangeFilter: FC<RangeFilterProps> = ({
  value,
  onChangeFirstValue,
  onChangeSecondValue,
  limits,
  step = (limits[1] - limits[0]) / 10,
  mask,
}) => {
  const firstValue = value[0];
  const secondValue = value[1];

  const minValue = limits[0];
  const maxValue = limits[1];

  const handleChangeFirstValue = useCallback(
    (input: string) => {
      onChangeFirstValue(parseInt(input));
    },
    [secondValue, onChangeFirstValue],
  );

  const handleChangeSecondValue = useCallback(
    (input: string) => {
      onChangeSecondValue(parseInt(input));
    },
    [firstValue, onChangeSecondValue],
  );

  const handleSliderChange = useCallback<NonNullable<SliderOwnProps['onChange']>>(
    (_, newValue, activeThumb) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      if (activeThumb === 0) {
        handleChangeFirstValue(newValue[0].toString());
      } else {
        handleChangeSecondValue(newValue[1].toString());
      }
    },
    [handleChangeFirstValue, handleChangeSecondValue],
  );

  return (
    <div className="grid grid-cols-2 gap-1">
      <Slider
        value={value}
        onChange={handleSliderChange}
        min={minValue}
        max={maxValue}
        step={step}
        disableSwap
        className="col-span-2"
      />
      <Input
        value={firstValue}
        onChange={(e) => handleChangeFirstValue(e.target.value)}
        mask={mask}
        {...COMMON_INPUT_PROPS}
      />
      <Input
        value={secondValue}
        onChange={(e) => handleChangeSecondValue(e.target.value)}
        mask={mask}
        {...COMMON_INPUT_PROPS}
      />
    </div>
  );
};
