import { useQueryPagination } from '@/hooks/useQueryPagination';
import { SearchQuery } from '@components/SearchFilters';
import { useSearchFiltersStore } from '@global/searchFilters';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { httpGetUserCompanies } from '../api/UserListsAPI';
import AddToListPopup from '../components/AddToListPopup';
import CompaniesTable from '../components/CompaniesTable';
import SaveQueryPopup from '../global/components/SaveQueryModal/SaveQueryPopup';
import useSearchForm from '../hooks/useSearchForm';

const MyCompanies = () => {
  const Navigate = useNavigate();

  const [showSaveQueryPopup, setShowSaveQueryPopup] = useState(false);
  const [allCompanies, setAllCompanies] = useState({
    data: [],
    totalRecords: 0,
  });

  const {
    isQueryValid,
    go,
    search,
    saveToMyArea,
    saved,
    isLoading,
    response,
    inputError,
    showAddToListPopup,
    setShowAddToListPopup,
    showFilters,
    selectedIds,
    setSelectedIds,
  } = useSearchForm(true, '/search-in-lists');

  const goBack = () => {
    Navigate(-1);
  };

  const handleSaveConfirm = async (title, signalTypes) => {
    await saveToMyArea(title, signalTypes);
  };

  const query = useSearchFiltersStore((state) => state.query);
  const isRegularSearch = useSearchFiltersStore((state) => state.isRegularSearch);
  const handleChangeIsRegularSearch = useSearchFiltersStore((state) => state.actions.setIsRegularSearch);

  const { offset, limit } = useQueryPagination();

  useEffect(() => {
    if (!query) return;

    httpGetUserCompanies({ offset: offset * limit, limit }).then((response) => {
      setAllCompanies(response.data);
    });
  }, [offset, limit, query]);

  useEffect(() => {
    if (saved) {
      setShowSaveQueryPopup(false);
    }
  }, [saved]);

  return (
    <div className="w-full">
      {showAddToListPopup && (
        <AddToListPopup handleModalToggle={() => setShowAddToListPopup(false)} companiesIds={selectedIds} />
      )}
      {showSaveQueryPopup && (
        <SaveQueryPopup handleClose={() => setShowSaveQueryPopup(false)} handleSaveConfirm={handleSaveConfirm} />
      )}
      <form className="items-left flex w-full flex-col justify-center" onSubmit={go}>
        <div className="mb-5 flex w-full items-center gap-4 lg:flex-row lg:gap-10">
          <SearchQuery inputError={inputError} isQueryValid={isQueryValid} placeholder="Search in your companies..." />
          <button type="submit" className="btn btn-primary h-10 w-20 rounded-full shadow-md lg:w-40">
            Search
          </button>
          <div className="flex items-center lg:flex-grow lg:gap-7">
            <div className="flex hidden flex-col items-center rounded-lg bg-base-200 px-4 py-2 shadow-md lg:block">
              <div className="flex w-full justify-between gap-4 text-sm">
                <div className="overflow-hidden whitespace-nowrap text-[10px]">
                  <b>AI Search</b>
                </div>
                <div className="text-[10px]">Traditional</div>
              </div>
              <div className="flex w-full justify-center">
                <input
                  type="checkbox"
                  className={`toggle ${
                    isRegularSearch
                      ? 'border-neutral bg-neutral hover:bg-neutral hover:opacity-80'
                      : 'border-primary-600 bg-primary-600 hover:bg-primary-600 hover:opacity-80'
                  }`}
                  onChange={(e) => handleChangeIsRegularSearch(e.target.checked)}
                  checked={isRegularSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      {!query && allCompanies.totalRecords > 0 && (
        <CompaniesTable
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          response={allCompanies}
          size={showFilters ? 'small' : 'medium'}
          displaySimilarity={false}
          displayLists={true}
          search={search}
        />
      )}
      {isLoading ? (
        <div className="flex items-center justify-center pt-20">
          <ClipLoader color="primary" size="40px" />
        </div>
      ) : (
        query &&
        response?.data?.data?.length > 0 && (
          <CompaniesTable
            setSelectedIds={setSelectedIds}
            selectedIds={selectedIds}
            response={response.data}
            size={showFilters ? 'small' : 'medium'}
            displaySimilarity={false}
            displayLists={true}
          />
        )
      )}
      {((response?.data?.length == 0 && query) || (!query && allCompanies.length == 0)) && !isLoading && (
        <div className="card-elevation flex w-full flex-col items-center justify-start gap-5 bg-center p-40">
          <h2 className="text-3xl font-bold text-base-content lg:text-5xl">Ops...</h2>
          <p className="mb-4 text-lg">It seems like we haven't any companies meeting your criteria.</p>
          <button className="btn btn-primary" onClick={goBack}>
            Go back
          </button>
        </div>
      )}

      {/* Manipulate Selected */}
      {selectedIds.length > 0 && (
        <div className="fixed bottom-20 left-1/2 flex w-[60%] -translate-x-1/2 transform flex-col items-center justify-center rounded-lg bg-base-100 py-3 shadow-md sm:flex-row md:gap-4 lg:w-fit lg:px-5">
          <div className="">{selectedIds.length} selected</div>
          <div className="text-2xl font-thin text-neutral">|</div>
          <button className="btn btn-ghost" onClick={() => setShowAddToListPopup(true)}>
            <FaPlus className="bg-clip-text text-purple-500" />
            <span className="bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text text-transparent">
              Add to my Lists
            </span>
          </button>
          {/* <button className="btn btn-ghost">
                <MdOutlineDriveFileMove size={22} className="bg-clip-text text-purple-500 " />
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500">
                  Move to other List
                </span>
              </button> */}
        </div>
      )}
    </div>
  );
};

export default MyCompanies;
