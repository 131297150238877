const FundingInfo = ({ company }) => {
  return (
    <div className="flex flex-col justify-between gap-4">
      {/* Title*/}
      <h2 className="text-lg font-semibold">Funding Insights</h2>
      {company?.totalRounds > 0 ? (
        <table className="table w-full">
          <thead className="text-left font-semibold opacity-70">
            <tr>
              <th className="border-b"></th>
              <th className="border-b">Amount</th>
              <th className="border-b">Stage</th>
              <th className="border-b">Date</th>
            </tr>
          </thead>
          <tbody className="text-left font-semibold">
            {company?.lastRoundAmountRaised && company?.lastRoundType && company?.lastRoundDate && (
              <tr>
                <td className="border-b text-left opacity-70">Latest Funding Round</td>
                <td className="border-b"> {company?.lastRoundAmountRaised}</td>
                <td className="border-b"> {company?.lastRoundType}</td>
                <td className="border-b"> {company?.lastRoundDate}</td>
              </tr>
            )}
            <tr className="">
              {' '}
              <td className="text-left opacity-70"></td>
            </tr>
            {/* TODO: Currently we don't have this field in mongodb */}
            {/* {fundingInfo.firstListedInvestor && (
            <tr className="">
              <td className="border-b text-left opacity-70">Key Investors</td>
              <td className="border-b"> {fundingInfo.firstListedInvestor.name}</td>
            </tr>
          )} */}
            {company?.totalRounds && (
              <tr className="">
                <td className="border-b text-left opacity-70">Total Rounds</td>
                <td className="border-b"> {company.totalRounds}</td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <div className="italic">No founding info</div>
      )}
    </div>
  );
};

export default FundingInfo;
