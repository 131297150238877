import { api } from '@/api/apiV2';
import { QueryKeys } from '@/api/entities/queryKeys';
import { DeepDiveResponse } from '@/api/generated/Api';
import { DeepDiveDetailsPathParams } from '@/router/types';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

type useDeepDiveDetailsArgs = {
  _id?: string;
  signal?: AbortSignal;
  onSuccess?: (data: DeepDiveResponse) => void;
};

export const useDeepDiveDetails = ({ _id, signal, onSuccess }: useDeepDiveDetailsArgs) => {
  const { deepDiveId } = useParams<DeepDiveDetailsPathParams>();
  const id = _id ?? deepDiveId;

  if (!id) throw new Error('No id provided and no id in URL found');

  const { data, isFetching } = useQuery<DeepDiveResponse>({
    queryKey: [QueryKeys.DeepDiveDetails, id],
    queryFn: async () => {
      const { data } = await api.deepDive.getDeepDive(id, {
        signal,
      });

      onSuccess?.(data);

      return data;
    },
    staleTime: 1000 * 60 * 2, // 2 minutes
  });

  return {
    deepDive: data,
    isDeepDiveLoading: isFetching,
  };
};
