import { TAGS_OPTIONS } from '@/data/options';
import { MultiSelect } from '@components/MultiSelect';
import { useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard } from '../common';

export const TagsFilter: FC = () => {
  const tags = useSearchFiltersStore((state) => state.tags);
  const toggleTag = useSearchFiltersStore((state) => state.actions.toggleTag);

  return (
    <FilterCard label={{ text: 'tags' }}>
      <MultiSelect
        options={TAGS_OPTIONS}
        selectedItems={tags}
        handleToggleItem={toggleTag}
        isSearchable
        inputClassName={undefined}
        optionLabelClassName={undefined}
      />
    </FilterCard>
  );
};
