import { DEFAULT_SEARCH_FILTERS, useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard, RangeFilter } from '../common';

export const EBITDAMarginFilter: FC = () => {
  const EBITDAMargin = useSearchFiltersStore((state) => state.EBITDAMargin);
  const setEBITDAMargin = useSearchFiltersStore((state) => state.actions.setEBITDAMargin);

  return (
    <FilterCard label={{ text: 'EBITDA_Margin' }}>
      <RangeFilter
        value={EBITDAMargin}
        onChangeFirstValue={(value) => setEBITDAMargin([value, EBITDAMargin[1]])}
        onChangeSecondValue={(value) => setEBITDAMargin([EBITDAMargin[0], value])}
        limits={[DEFAULT_SEARCH_FILTERS.EBITDAMargin[0], DEFAULT_SEARCH_FILTERS.EBITDAMargin[1]]}
        step={10}
        mask={{
          alias: 'percentage',
          min: DEFAULT_SEARCH_FILTERS.EBITDAMargin[0],
          max: DEFAULT_SEARCH_FILTERS.EBITDAMargin[1],
        }}
      />
    </FilterCard>
  );
};
