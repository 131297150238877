import type { ChangeEvent } from 'react';
import { ALIAS_CONFIGURATION } from './constants';
import { MaskWithOptions } from './types';

export const getMask = (mask: MaskWithOptions) => {
  if (mask.alias && Object.keys(ALIAS_CONFIGURATION).includes(mask.alias))
    return { ...ALIAS_CONFIGURATION[mask.alias], ...mask, alias: ALIAS_CONFIGURATION[mask.alias].alias };

  return mask;
};

export const maskOnChangeFunction = (e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
  const { selectionStart, style } = e.target;
  style.caretColor = 'transparent';
  onChange(e);
  setTimeout(() => {
    e.target.selectionStart = e.target.selectionEnd = selectionStart;
    style.caretColor = 'auto';
  }, 0);
};
