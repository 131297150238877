// src/hooks/useSearchForm.js
import { QueryKeys } from '@/api/entities/queryKeys';
import { MIN_OFFSET } from '@components/Pagination';
import { useSearchFiltersStore } from '@global/searchFilters';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { httpSaveSearchToMyArea, httpSearchCompanies } from '../api/CompaniesAPI';
import { useQueryPagination } from './useQueryPagination';

const useSearchForm = (triggerSearchOnLoad = true, apiEndpoint = '/search', path) => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [inputError, setInputError] = useState('');
  const [showAddToListPopup, setShowAddToListPopup] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [saved, setSaved] = useState(false);

  const queryClient = useQueryClient();

  const Navigate = useNavigate();

  const { actions, ...formData } = useSearchFiltersStore();

  useEffect(() => {
    setSaved(false);
  }, [formData]);

  const isQueryValid = () => {
    if (
      formData.query.length === 0 &&
      (formData.companySizes.length === 0 || !formData.selectedRegion || formData.tags.length === 0)
    ) {
      setInputError('Please, enter at least 3 required filters (Company Size, Region, Tags) to search without query');
      return false;
    }

    if (formData.query.length < 3 && formData.query.length >= 0 && formData.type === false) {
      actions.setIsRegularSearch(true);
    }

    setInputError('');
    return true;
  };

  const { setPage } = useQueryPagination();

  const search = (resetPage = false) => {
    setIsLoading(true);

    if (resetPage) {
      setPage(MIN_OFFSET);
    }

    httpSearchCompanies(formData, apiEndpoint, resetPage)
      .then((response) => {
        setResponse(response);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveToMyArea = async (title, signalTypes) => {
    try {
      await httpSaveSearchToMyArea(formData, title, signalTypes);
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.Queries],
      });
      setSaved(true);
    } catch (error) {
      console.error(error);
    }
  };

  //
  const validateGo = () => {
    if (
      formData.query.length === 0 &&
      (formData.companySizes.length === 0 || !formData.selectedRegion || formData.tags.length === 0)
    ) {
      setInputError('Please, enter at least 3 required filters (Company Size, Region, Tags) to search without query');
      return {
        validationResult: false,
      };
    }

    if (
      formData.query.length === 0 &&
      formData.companySizes.length > 0 &&
      !!formData.selectedRegion &&
      formData.tags.length > 0 &&
      formData.type === false
    ) {
      setInputError('');
      actions.setIsRegularSearch(true);
      return {
        validationResult: true,
      };
    }

    setInputError('');
    return {
      validationResult: true,
    };
  };

  const go = (event) => {
    event.preventDefault();

    const validation = validateGo();

    if (validation.validationResult) {
      search(true);
      if (path) {
        Navigate(`${path}${apiEndpoint.includes('similar-companies') ? '?tab=similar&' : ''}`);
      }
    } else {
      setInputError('Please enter at least 3 characters to search');
    }
  };

  useEffect(() => {
    if (triggerSearchOnLoad) {
      setShouldSearch(true);
    }
  }, [triggerSearchOnLoad]);

  useEffect(() => {
    if (shouldSearch) {
      search();
      setShouldSearch(false);
    }
  }, [shouldSearch]);

  return {
    isQueryValid,
    search,
    saveToMyArea,
    saved,
    go,
    isLoading,
    response,
    inputError,
    showAddToListPopup,
    setShowAddToListPopup,
    showFilters,
    setShowFilters,
    selectedIds,
    setSelectedIds,
  };
};

export default useSearchForm;
