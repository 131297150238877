import { StakeholdersInsightResponse } from '@/api/generated/Api';
import { InitialTableState } from '@components/Tables';
import { Typography } from '@components/Typography';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { formatPercentage } from '@utils';
import { useMemo } from 'react';
import { ShareholdersInsightTableItem } from './types';

const columnHelper = createColumnHelper<ShareholdersInsightTableItem>();

export const useShareholdersInsightsTableInitialState = () => {
  const columns = useMemo(
    () =>
      [
        // columnHelper.display({
        //   id: 'label',
        //   cell: () => <div className="h-10 w-1 bg-red-500" />,
        //   header: '',
        //   minSize: 10,
        //   maxSize: 10,
        //   enableSorting: false,
        //   meta: {
        //     className: () => 'p-0',
        //   },
        // }),
        columnHelper.accessor('name', {
          id: 'name',
          cell: ({ getValue, row }) => (
            <>
              <Typography
                arbitrary
                text={getValue() ?? '[Investor Name]'}
                variant="sm/bold"
                className="first-letter:capitalize"
              />
              <Typography
                arbitrary
                text={row.original.genderAndAge ?? 'NA'}
                variant="xs/regular"
                className="first-letter:capitalize"
              />
            </>
          ),
          header: 'name',
          minSize: 200,
          maxSize: 200,
          enableSorting: false,
          meta: {
            className: () => 'font-bold first-letter:capitalize *:text-grey-900',
            nestedClassName: () => '*:text-grey-900',
          },
        }),
        columnHelper.accessor('ownershipPercentage', {
          cell: (info) => formatPercentage(info.getValue()),
          header: 'ownership',
          minSize: 200,
          maxSize: 200,
          enableSorting: false,
        }),
        columnHelper.accessor('fiscalCode', {
          id: 'fiscalNumber',
          cell: (info) => info.getValue(),
          header: 'fiscalNumber',
          minSize: 100,
          maxSize: 100,
          enableSorting: false,
        }),
      ] as ColumnDef<StakeholdersInsightResponse>[],
    [],
  );

  return useMemo(
    () =>
      ({
        sorting: [{ id: 'name', desc: true }],
        columns,
      }) as InitialTableState<ShareholdersInsightTableItem>,
    [columns],
  );
};
