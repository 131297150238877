import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';
import Inputmask from 'inputmask';
import type { ChangeEvent, DetailedHTMLProps, FC, ForwardedRef, InputHTMLAttributes } from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getMask, maskOnChangeFunction } from './helpers';
import { MaskWithOptions } from './types';

export type InputProps = {
  errorMsg?: string;
  wrapperClassName?: string;
  isLoading?: boolean;
  mask?: MaskWithOptions;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const styles = cva(
  'h-[39px] w-full rounded-md border-disabled bg-fill-input font-bold px-4 placeholder:text-black/50 disabled:cursor-not-allowed disabled:text-black/25 text-grey-900 focus-visible:outline-grey-700',
  {
    variants: {
      hasError: {
        true: 'border-red-500',
      },
    },
  },
);

const InputComp = (
  { errorMsg, wrapperClassName, className, placeholder, isLoading, mask, onChange, ...props }: InputProps,
  forwardRef: ForwardedRef<HTMLInputElement>,
) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (mask && ref.current) {
      Inputmask({
        ...getMask(mask),
        oncleared: () => {
          ref.current!.value = '';
        },
      }).mask(ref.current);
    }
  }, [mask?.alias, mask?.mask]);

  const internalOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (mask && onChange) return maskOnChangeFunction(e, onChange);

      if (onChange) return onChange(e);

      return undefined;
    },
    [mask?.alias, mask?.mask, onChange],
  );

  useImperativeHandle(forwardRef, () => ref.current as HTMLInputElement);

  return (
    <div className={cn('relative w-full text-[12px] leading-[17px]', wrapperClassName)}>
      <input
        ref={ref}
        onChange={internalOnChange}
        type="text"
        className={cn(styles({ hasError: !!errorMsg }), className)}
        placeholder={t(placeholder) ?? undefined}
        spellCheck
        {...props}
      />
      {errorMsg ? (
        <p role="alert" className="text-red-500">
          {errorMsg}
        </p>
      ) : null}
    </div>
  );
};

export const Input = forwardRef<HTMLInputElement, InputProps>(InputComp) as FC<InputProps>;
