import { useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  CompanySizeFilter,
  EBITDAFilter,
  EBITDAMarginFilter,
  EntityTypeFilter,
  FundingFilter,
  ItalianRegionsFilter,
  LatestFundingFilter,
  MajorityShareholderAgeFilter,
  MinimumScoreFilter,
  NFPFilter,
  NumberOfShareholdersFilter,
  RegionFilter,
  RevenueFilter,
  TagsFilter,
  TargetMarketsFilter,
} from './filters';
import { FoundingDateFilter } from './filters/FoundingDateFilter';

type SearchFiltersProps = {
  isVisible?: boolean;
  wrapperClassName?: string;
  className?: string;
};

export const SearchFilters: FC<SearchFiltersProps> = ({ isVisible, wrapperClassName, className }) => {
  const isItalyFilterType = useSearchFiltersStore((state) => state.filterType === 'Italy');

  return (
    <article
      aria-expanded={isVisible}
      className={twMerge(
        'max-h-0 overflow-x-auto overflow-y-hidden px-2 opacity-0 ease-in-out aria-expanded:max-h-[600px] aria-expanded:pb-5 aria-expanded:opacity-100 motion-safe:transition-all motion-safe:duration-300 motion-reduce:transition-none',
        wrapperClassName,
      )}
    >
      <div className={twMerge('grid grid-cols-[repeat(16,minmax(200px,1fr))] gap-[10px]', className)}>
        <MinimumScoreFilter />
        <CompanySizeFilter />
        <RegionFilter />
        <FoundingDateFilter />
        <FundingFilter />
        <LatestFundingFilter />
        <TagsFilter />
        <EntityTypeFilter />
        <TargetMarketsFilter />
        {isItalyFilterType ? (
          <>
            <RevenueFilter />
            <EBITDAFilter />
            <NFPFilter />
            <NumberOfShareholdersFilter />
            <MajorityShareholderAgeFilter />
            <ItalianRegionsFilter />
            <EBITDAMarginFilter />
          </>
        ) : null}
      </div>
    </article>
  );
};
