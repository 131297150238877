import { FilterCard } from '@components/SearchFilters/common/FilterCard';
import { useSearchFiltersStore } from '@global/searchFilters';
import { Slider } from '@mui/material';
import { FC, useCallback } from 'react';
import { SliderOnChangeFunc } from '../types';

const minDistance = 0;

export const MinimumScoreFilter: FC = () => {
  const scoreFrom = useSearchFiltersStore((state) => state.scoreFrom);
  const setScoreFrom = useSearchFiltersStore((state) => state.actions.setScoreFrom);

  const handleRelevantScoreFilter = useCallback<SliderOnChangeFunc>(
    (_, newValue, activeThumb) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      if (newValue[1] - newValue[0] < minDistance) {
        if (activeThumb === 0) {
          const clamped = Math.min(newValue[0], 100 - minDistance);
          setScoreFrom(clamped);
        } else {
          const clamped = Math.max(newValue[1], minDistance);
          setScoreFrom(clamped - minDistance);
        }
      } else {
        setScoreFrom(newValue[0]);
      }
    },
    [scoreFrom, setScoreFrom],
  );

  return (
    <FilterCard label={{ text: 'minimumScore' }}>
      <div>
        <Slider
          getAriaLabel={() => 'Relevant Score slider'}
          value={[scoreFrom, 100]}
          onChange={handleRelevantScoreFilter}
          valueLabelDisplay="auto"
          min={0}
          max={100}
          disableSwap
          step={10}
          sx={{
            '& .MuiSlider-thumb': {
              height: 16,
              width: 16,
              backgroundColor: '##7779FF',
            },
            '& .MuiSlider-thumb:last-of-type': {
              display: 'none',
            },
          }}
        />
        <div className="flex items-center justify-between text-[12px] dark:text-slate-600">
          <span>{scoreFrom}</span>
          <span>{100}</span>
        </div>
      </div>
    </FilterCard>
  );
};
