import { NestedSelectOption } from '@components/NestedSelect/types';
import { ItalianRegions, Tags, TargetMarkets } from '@global/types';
import { enumToSelectOptions } from '@utils/enum/enumToSelectOptions';

export const LATEST_FUNDING_TYPE_OPTIONS = [
  { value: 0, label: 'Angel' },
  { value: 1, label: 'Seed' },
  { value: 2, label: 'Series Unknown' },
  { value: 3, label: 'Pre Seed' },
  { value: 4, label: 'Series A' },
  { value: 5, label: 'Series B' },
  { value: 6, label: 'Series C' },
  { value: 7, label: 'Series D' },
  { value: 8, label: 'Series E' },
  { value: 9, label: 'Series F' },
  { value: 10, label: 'Series G' },
];

export const REGION_OPTIONS = [
  { value: 'Albania', label: 'Albania' },
  { value: 'Algeria', label: 'Algeria' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Azerbaijan', label: 'Azerbaijan' },
  { value: 'Bahamas', label: 'Bahamas' },
  { value: 'Bahrain', label: 'Bahrain' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Bermuda', label: 'Bermuda' },
  { value: 'Bolivia', label: 'Bolivia' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Botswana', label: 'Botswana' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Cambodia', label: 'Cambodia' },
  { value: 'Cameroon', label: 'Cameroon' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Cayman Islands', label: 'Cayman Islands' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic', label: 'Czech Republic' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Dominican Republic', label: 'Dominican Republic' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'England', label: 'England' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Ethiopia', label: 'Ethiopia' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Gibraltar', label: 'Gibraltar' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guernsey', label: 'Guernsey' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hong Kong', label: 'Hong Kong' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'India', label: 'India' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Iraq', label: 'Iraq' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Isle of Man', label: 'Isle of Man' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Jersey', label: 'Jersey' },
  { value: 'Jordan', label: 'Jordan' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Lebanon', label: 'Lebanon' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Macedonia', label: 'Macedonia' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Mauritius', label: 'Mauritius' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Morocco', label: 'Morocco' },
  { value: 'Myanmar', label: 'Myanmar' },
  { value: 'Namibia', label: 'Namibia' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'Northern Ireland', label: 'Northern Ireland' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palestine', label: 'Palestine' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Philippines', label: 'Philippines' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Puerto Rico', label: 'Puerto Rico' },
  { value: 'Qatar', label: 'Qatar' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Russian Federation', label: 'Russian Federation' },
  { value: 'Rwanda', label: 'Rwanda' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia' },
  { value: 'Scotland', label: 'Scotland' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'South Africa', label: 'South Africa' },
  { value: 'South Korea', label: 'South Korea' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Taiwan', label: 'Taiwan' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Thailand', label: 'Thailand' },
  { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
  { value: 'Tunisia', label: 'Tunisia' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'United Arab Emirates', label: 'United Arab Emirates' },
  { value: 'United Kingdom', label: 'United Kingdom' },
  { value: 'United States', label: 'United States' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Uzbekistan', label: 'Uzbekistan' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Zimbabwe', label: 'Zimbabwe' },
];

export const industryOptions = [
  { value: 0, label: 'Accounting' },
  { value: 1, label: 'Advertising Services' },
  { value: 2, label: 'Appliances, Electrical, and Electronics Manufacturing' },
  { value: 3, label: 'Computers and Electronics Manufacturing' },
  { value: 4, label: 'Entertainment Providers' },
  { value: 5, label: 'Environmental Services' },
  { value: 6, label: 'Financial Services' },
  { value: 7, label: 'Freight and Package Transportation' },
  { value: 8, label: 'Information Services' },
  { value: 9, label: 'Information Technology & Services' },
  { value: 10, label: 'Insurance' },
  { value: 11, label: 'International Affairs' },
  { value: 12, label: 'IT Services and IT Consulting' },
  { value: 13, label: 'Medical Device' },
  { value: 14, label: 'Medical Equipment Manufacturing' },
  { value: 15, label: 'Non-profit Organization Management' },
  { value: 16, label: 'Packaging and Containers Manufacturing' },
  { value: 17, label: 'Personal Care Product Manufacturing' },
  { value: 18, label: 'Photography' },
  { value: 19, label: 'Plastics Manufacturing' },
  { value: 20, label: 'Renewable Energy Equipment Manufacturing' },
  { value: 21, label: 'Renewable Energy Semiconductor Manufacturing' },
  { value: 22, label: 'Retail' },
  { value: 23, label: 'Retail Apparel and Fashion' },
  { value: 24, label: 'Settore alberghiero' },
  { value: 25, label: 'Software Development' },
  { value: 26, label: 'Technology, Information and Internet' },
  { value: 27, label: 'Travel Arrangements' },
  { value: 28, label: 'Truck Transportation' },
  { value: 29, label: 'Utilities' },
  { value: 30, label: 'Venture Capital and Private Equity Principals' },
  { value: 31, label: 'Wellness and Fitness Services' },
];

export const COMPANY_SIZE_OPTIONS = [
  { value: 0, label: '1 employee' },
  { value: 1, label: '2-10 employees' },
  { value: 2, label: '11-50 employees' },
  { value: 3, label: '51-200 employees' },
  { value: 4, label: '201-500 employees' },
  { value: 5, label: '501-1,000 employees' },
  { value: 6, label: '1,001-5,000 employees' },
  { value: 7, label: '5,001-10,000 employees' },
  { value: 8, label: '10,001+ employees' },
];

export const TAGS_OPTIONS = Object.keys(Tags).map((tag, index) => ({
  value: index,
  label: tag,
}));

export const TARGET_MARKETS_OPTIONS = Object.keys(TargetMarkets).map((tag, index) => ({
  value: index,
  label: tag,
}));

export const ITALIAN_REGIONS_OPTIONS = enumToSelectOptions(ItalianRegions);

export enum CompanyEntityTypeL2 {
  StartupOrScaleup = 'Startup/Scaleup',
}

export const COMPANY_ENTITY_TYPES = [CompanyEntityTypeL2.StartupOrScaleup, 'Local Business', 'Other Company'];

export const COMPANY_TYPE_OPTIONS: NestedSelectOption[] = [
  {
    label: 'Company',
    options: COMPANY_ENTITY_TYPES.map((type) => ({
      label: type,
      value: type,
    })),
  },
  {
    label: 'Fund',
    value: 'Fund',
  },
  {
    label: 'Institution',
    value: 'Institution',
  },
  {
    label: 'Event',
    value: 'Event',
  },
  {
    label: 'Government Entity',
    value: 'Government Entity',
  },
  {
    label: 'Association/Non-profit Organization',
    value: 'Association/Non-profit Organization',
  },
];

export const RAW_COMPANY_TYPE_OPTIONS = COMPANY_TYPE_OPTIONS.flatMap((option) => option.options ?? option);
