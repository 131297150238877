import { Button } from '@components/Button';
import { LocalizedText, Typography } from '@components/Typography';
import { ComponentProps, FC } from 'react';
import { IoSearchOutline } from 'react-icons/io5';

type SearchFilterTypeButtonProps = {
  label: LocalizedText;
  onClick: ComponentProps<typeof Button>['onClick'];
  isActive?: boolean;
  description?: LocalizedText;
};

export const SearchFilterTypeButton: FC<SearchFilterTypeButtonProps> = ({
  label,
  onClick,
  isActive = true,
  description,
}) => {
  return (
    <Button
      intent={isActive ? 'accent' : 'empty'}
      onClick={onClick}
      aria-selected={isActive}
      className="group items-center gap-[10px] rounded-lg px-[1.125rem]"
    >
      <IoSearchOutline size={20} />
      <div className="flex items-baseline gap-1 text-inherit *:normal-case">
        <Typography
          {...label}
          variant="lg/bold"
          className="text-inherit first-letter:capitalize group-aria-[selected=false]:font-normal"
        />
        {description ? <Typography {...description} variant="xs/regular" className="text-inherit" /> : null}
      </div>
    </Button>
  );
};
