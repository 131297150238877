export const alignStyles = (align?: 'left' | 'right' | 'center') => {
  switch (align) {
    case 'center':
      return {
        headerClassName: 'justify-center',
        headerTextClassName: 'justify-center',
        cellClassName: 'text-center justify-center',
      };
    case 'right':
      return {
        headerClassName: 'justify-end',
        headerTextClassName: 'justify-end',
        cellClassName: 'text-right justify-end',
      };
    case 'left':
    default:
      return {
        headerClassName: 'justify-between',
        headerTextClassName: 'text-left',
        cellClassName: 'text-left justify-start',
      };
  }
};
