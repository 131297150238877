import { mapFiltersToSearchRequest } from '@global/searchFilters/helpers';
import { SearchFiltersValues } from '@global/searchFilters/types';
import { customAxios } from './customAxios';
import { SignalType } from './generated/Api';

const PATH = '/api/companies';
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;

export function httpGetCompanyInfo(companyId: string) {
  return customAxios.get(`${API_ENDPOINT}${PATH}/${companyId}`);
}

export function httpGetSimilarCompanies(companyId: string, data: any) {
  return customAxios.post(`${API_ENDPOINT}${PATH}/${companyId}/similar-companies`, data);
}

export function httpCreateCompanyNotes(companyId: string, title: string, content: any) {
  return customAxios.post(`${API_ENDPOINT}${PATH}/${companyId}/notes`, {
    userId: localStorage.getItem('userId'),
    title: title,
    content: content,
  });
}

export function httpGetCompanyNotes(companyId: string) {
  return customAxios.get(`${API_ENDPOINT}${PATH}/${companyId}/notes?userId=${localStorage.getItem('userId')}`);
}

export function httpDeleteCompanyNotes(companyId: string, noteId: string) {
  return customAxios.delete(`${API_ENDPOINT}${PATH}/${companyId}/notes/${noteId}`);
}

export function httpUpdateCompanyNotes(companyId: string, noteId: string, title: string, content: any) {
  return customAxios.put(`${API_ENDPOINT}${PATH}/${companyId}/notes/${noteId}`, {
    userId: localStorage.getItem('userId'),
    title: title,
    content: content,
  });
}

export function httpSearchCompanies(filters: SearchFiltersValues, apiEndpoint: string) {
  const requestObject = mapFiltersToSearchRequest(filters);

  return customAxios.post(`${API_ENDPOINT}${PATH}${apiEndpoint}`, requestObject);
}

export function httpSaveSearchToMyArea(filters: SearchFiltersValues, title: string, signalTypes: SignalType[]) {
  const mappedFilters = mapFiltersToSearchRequest(filters);

  const requestObject = {
    ...mappedFilters,
    title,
    signalTypes,
  };

  return customAxios.post(`${API_ENDPOINT}${PATH}/search`, requestObject);
}
