import { cn } from '@/lib/utils';
import { TableElement } from '@components/Tables/types';
import type { Cell, ColumnMeta } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

type TableCellProps<T extends TableElement> = {
  cell: Cell<T, unknown>;
  className?: string;
};

export const TableCell = <T extends TableElement>({ cell, className }: TableCellProps<T>) => {
  const meta = cell.column.columnDef.meta as ColumnMeta<T, unknown>;

  const cellType = typeof cell.getValue();

  const columnDefCell =
    typeof cell.column.columnDef.cell === 'function'
      ? (cell.column.columnDef.cell?.(cell.getContext()) as string | number | undefined)
      : cell.column.columnDef.cell;
  const cellValue = typeof columnDefCell === 'string' || typeof columnDefCell === 'number' ? columnDefCell : null;

  return (
    <td
      key={cell.id}
      align={meta?.align}
      className={cn(
        'border-border relative truncate border-b-[1px] py-[6px] pl-2 pr-3 text-sm',
        className,
        meta?.className?.(cell.getContext()),
      )}
      {...{
        style: {
          maxWidth: cell.column.columnDef.maxSize,
          minWidth: cell.column.columnDef.minSize,
          width: cell.column.columnDef.size,
        },
      }}
    >
      <div
        title={cellValue?.toString()}
        className={cn(
          'w-fit max-w-full [&>button]:ml-1',
          cellType === 'string' ? 'truncate' : undefined,
          meta?.nestedClassName?.(cell.getContext()),
        )}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </div>
    </td>
  );
};
