import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryPagination } from './useQueryPagination';

export const useDefaultPagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchOffset = searchParams.get('offset');
  const searchLimit = searchParams.get('limit');

  const { limit, offset } = useQueryPagination();

  useEffect(() => {
    if (searchOffset === null || searchLimit === null) {
      setSearchParams(
        (prev) => ({
          ...Object.fromEntries([...prev]),
          offset: offset.toString(),
          limit: limit.toString(),
        }),
        {
          replace: true,
        },
      );
    }
  }, [searchOffset, searchLimit, offset, limit]);
};
