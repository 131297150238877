import { ITALIAN_REGIONS_OPTIONS } from '@/data/options';
import { MultiSelect } from '@components/MultiSelect';
import { useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard } from '../common';

export const ItalianRegionsFilter: FC = () => {
  const italianRegions = useSearchFiltersStore((state) => state.italianRegions);
  const toggleItalianRegions = useSearchFiltersStore((state) => state.actions.toggleItalianRegions);

  return (
    <FilterCard label={{ text: 'italianRegions' }}>
      <MultiSelect
        options={ITALIAN_REGIONS_OPTIONS}
        selectedItems={italianRegions}
        handleToggleItem={toggleItalianRegions}
        isSearchable
        inputClassName={undefined}
        optionLabelClassName={undefined}
      />
    </FilterCard>
  );
};
