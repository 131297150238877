import { LocalizedText, Typography } from '@components/Typography';
import { TileLayout } from '@global/components/TileLayout';
import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type FilterCardProps = {
  label: LocalizedText;
  labelClassName?: string;
} & PropsWithChildren;

export const FilterCard: FC<FilterCardProps> = ({ label, labelClassName, children }) => (
  <TileLayout className="size-full gap-2 justify-between">
    <Typography
      {...label}
      variant="xs/bold"
      className={twMerge('text-grey-900 first-letter:capitalize', labelClassName)}
    />
    {children}
  </TileLayout>
);
