import { useCompany } from '@/api/entities/companies';
import { CompanyEntityTypeL2 } from '@/data/options';
import { useAbortController } from '@/hooks';
import useSearchForm from '@/hooks/useSearchForm';
import { SvgIcon } from '@components/SvgIcon';
import { Loadable } from '@global/components/Loadable';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import CompanyOverview from '../../components/CompanyOverview';
import SimilarCompanies from '../../components/SimilarCompanies';
import { Assistant, Team } from './parts';

function Company() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'overview');
  const { id } = useParams();

  const searchFormProps = useSearchForm(true, `/${id}/similar-companies`);

  const handleTabClick = (tab) => {
    navigate(`/company/${id}?tab=${tab}`);
  };

  useEffect(() => {
    setActiveTab(searchParams.get('tab') || 'overview');
  }, [searchParams]);

  const tabStyles = {
    '.differentTab > .tab.tabActive:not(.tabDisabled):not([disabled])': {
      borderImage: 'linear-gradient(to right, rgb(0 165 58), rgb(0 111 14))',
    },
  };

  const { signal } = useAbortController();

  const { company, isFetching } = useCompany({
    companyId: id,
    signal,
  });

  return (
    <div className="w-full">
      {isFetching ? (
        <div className="justify-left flex h-[80vh] items-center">
          <BeatLoader color={'#123abc'} loading={isFetching} />
        </div>
      ) : (
        <div className={`flex w-full flex-col items-start justify-start`}>
          <Loadable
            isLoading={isFetching}
            className="pb-4"
            spinnerProps={{
              size: 'sm',
            }}
          >
            <div className={`'z-0' tabs relative z-0 flex w-full items-center justify-start pb-4`}>
              <a
                className={`mr-10 cursor-pointer pb-1 ${
                  activeTab === 'overview' ? 'font-semibold' : 'font-regular'
                } text-lg`}
                onClick={() => handleTabClick('overview')}
              >
                Overview
              </a>
              <a
                style={tabStyles}
                className={`mr-10 cursor-pointer pb-1 ${
                  activeTab === 'similar' ? 'font-semibold' : 'font-regular'
                } text-lg`}
                onClick={() => handleTabClick('similar')}
              >
                Similar Companies
              </a>
              {company.entityTypeL2 === CompanyEntityTypeL2.StartupOrScaleup ? (
                <>
                  <a
                    style={tabStyles}
                    className={`mr-10 cursor-pointer pb-1 ${activeTab === 'assistant' ? 'font-semibold' : 'font-regular'} flex items-center text-lg`}
                    onClick={() => handleTabClick('assistant')}
                  >
                    Assistant
                    <SvgIcon name={'Assistante'} className="ml-1 h-6 w-6" />
                  </a>
                  <a
                    style={tabStyles}
                    className={`mr-10 cursor-pointer pb-1 ${activeTab === 'team' ? 'font-semibold' : 'font-regular'} text-lg`}
                    onClick={() => handleTabClick('team')}
                  >
                    Team
                  </a>
                </>
              ) : null}
            </div>
          </Loadable>
          {activeTab === 'overview' && <CompanyOverview {...searchFormProps} />}
          {activeTab === 'similar' && <SimilarCompanies {...searchFormProps} />}
          {activeTab === 'team' && <Team />}
          {activeTab === 'assistant' && <Assistant />}
        </div>
      )}
    </div>
  );
}

export default Company;
