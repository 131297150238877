import { ArrowLeft, ArrowRight } from '@components/SvgIcon/Icons';
import { Typography } from '@components/Typography';
import { getCurrentQueryParams } from '@utils/queryParams';
import { FC } from 'react';
import { MIN_LIMIT, MIN_OFFSET } from './constants';
import { LimitSelector, PageButtons, PrevNextButton } from './parts';
import { PaginationQueryArgs } from './types';

type PaginationProps = {
  total: number;
  setLimit: (limit: number) => void;
  setPage: (page: number) => void;
};

export const Pagination: FC<PaginationProps> = ({ total, setLimit, setPage }) => {
  const { offset, limit } = getCurrentQueryParams<PaginationQueryArgs>(window.location.search);

  const actualOffset = Number(offset) ?? MIN_OFFSET;
  const actualLimit = Number(limit) ?? MIN_LIMIT;

  const totalPages = Math.ceil(total / actualLimit);
  const isFirstPage = actualOffset === MIN_OFFSET;
  const isLastPage = actualOffset + 1 >= totalPages;

  return (
    <footer className="flex items-center justify-between">
      <Typography arbitrary text={`${total} results`} variant="xs/medium" className="text-grey-900" />
      <nav className="flex items-center gap-2">
        <div className="flex items-center gap-2 px-3 py-2">
          <Typography
            arbitrary
            text="results per page"
            variant="xs/medium"
            className="text-grey-900 first-letter:capitalize"
          />
          <LimitSelector setLimit={setLimit} limit={actualLimit} />
        </div>
        <PrevNextButton disabled={isFirstPage} onClick={() => setPage(actualOffset - 1)}>
          <ArrowLeft disabled={isFirstPage} />
          <Typography text="previous" variant="xs/regular" className="capitalize text-grey-700" />
        </PrevNextButton>
        <PageButtons setPage={setPage} totalPages={totalPages} offset={actualOffset} />
        <PrevNextButton disabled={isLastPage} onClick={() => setPage(actualOffset + 1)}>
          <Typography text="next" variant="xs/regular" className="capitalize text-grey-700" />
          <ArrowRight disabled={isLastPage} />
        </PrevNextButton>
      </nav>
    </footer>
  );
};
