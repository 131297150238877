import { DEFAULT_SEARCH_FILTERS, useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard, RangeFilter } from '../common';

export const MajorityShareholderAgeFilter: FC = () => {
  const shareholderAge = useSearchFiltersStore((state) => state.shareholderAge);
  const setShareholderAge = useSearchFiltersStore((state) => state.actions.setShareholderAge);

  return (
    <FilterCard label={{ text: 'majorityShareholderAge' }}>
      <RangeFilter
        value={shareholderAge}
        onChangeFirstValue={(value) => setShareholderAge([value, shareholderAge[1]])}
        onChangeSecondValue={(value) => setShareholderAge([shareholderAge[0], value])}
        limits={[DEFAULT_SEARCH_FILTERS.shareholderAge[0], DEFAULT_SEARCH_FILTERS.shareholderAge[1]]}
        step={5}
        mask={{
          alias: 'integer',
          min: DEFAULT_SEARCH_FILTERS.shareholderAge[0],
          max: DEFAULT_SEARCH_FILTERS.shareholderAge[1],
        }}
      />
    </FilterCard>
  );
};
