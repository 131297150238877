import { type ColumnMeta, type Header, flexRender } from '@tanstack/react-table';
import type { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export const RowHeader: FC<{ header: Header<any, unknown>; className?: string }> = ({ header, className }) => {
  const headerMeta = header.column.columnDef.meta as ColumnMeta<any, unknown>;

  return (
    <th
      align={headerMeta?.align}
      rowSpan={header.colSpan}
      colSpan={headerMeta?.colSpan}
      className={twMerge('sticky-cell', 'left-0', className, headerMeta?.headerClassName?.(header.getContext()))}
      key={`${header.id}_header`}
      data-key={`${header.id}_header`}
    >
      <div className="flex size-full items-baseline whitespace-break-spaces text-left capitalize [overflow-wrap:anywhere]">
        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
      </div>
    </th>
  );
};
