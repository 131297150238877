import { mapLimit, mapOffset } from '@components/Pagination';
import { getCurrentQueryParams } from '@utils/queryParams';
import { customAxios } from './customAxios';

const PATH = '/api/user-lists';
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;

export function httpCreateUserList(data) {
  return customAxios.post(`${API_ENDPOINT}${PATH}`, data);
}
export function httpGetUserLists() {
  return customAxios.get(`${PATH}?userId=${localStorage.getItem('userId')}&limit=100`);
}
export function httpDeleteUserList(listId) {
  return customAxios.delete(`${PATH}/${listId}`);
}

export function httpGetListCompanies(listId) {
  const { limit, offset } = getCurrentQueryParams();

  const limitParam = mapLimit(limit);
  const offsetParam = mapOffset(offset);

  return customAxios.get(`${PATH}/${listId}/companies?offset=${offsetParam * limitParam}&limit=${limitParam}`);
}

export function httpGetListInfo(listId) {
  return customAxios.get(`${PATH}/${listId}`);
}

export function httpDeleteCompaniesFromList(listId, companiesIds) {
  let requestObject = {
    toAdd: [],
    toDelete: companiesIds,
  };
  return customAxios.put(`${PATH}/${listId}`, requestObject);
}

export function httpAddCompaniesToList(listId, companiesIds) {
  let requestObject = {
    toAdd: companiesIds,
    toDelete: [],
  };
  return customAxios.put(`${PATH}/${listId}`, requestObject);
}

export function httpAddCompaniesToMultipleLists(userListIds, companyIds) {
  let requestObject = {
    userListIds: userListIds,
    companyIds: companyIds,
    userId: localStorage.getItem('userId'),
  };
  return customAxios.post(`${PATH}/companies`, requestObject);
}

export function httpCheckCompanyInList(companyId) {
  let userId = localStorage.getItem('userId');

  return customAxios.get(`${PATH}/companies/check?userId=${userId}&companyId=${companyId}`);
}

export function httpGetUserCompanies({ offset, limit }) {
  return customAxios.get(`${API_ENDPOINT}${PATH}/companies?offset=${offset}&limit=${limit}`);
}
