import { useCallback, useEffect, useRef, useState } from 'react';

export const useHorizontalScrollRef = <T extends object>(data: T[]) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const bodyRef = useRef<HTMLDivElement | null>(null);

  const updateIsScrollable = useCallback(() => {
    if (!bodyRef.current) setIsScrollable(false);
    else setIsScrollable(bodyRef.current.scrollWidth > bodyRef.current.clientWidth);
  }, []);

  useEffect(() => {
    updateIsScrollable();
  }, [data]);

  useEffect(() => {
    if (!bodyRef.current) return;
    const current = bodyRef.current;
    const observer = new ResizeObserver(() => {
      updateIsScrollable();
    });
    observer.observe(current);
    return () => observer.disconnect();
  }, [bodyRef.current]);

  return {
    isScrollable,
    bodyRef,
  };
};
