import { mapLimit, mapOffset, MIN_OFFSET } from '@components/Pagination';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryPagination = (onChange?: VoidFunction) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchOffset = searchParams.get('offset');
  const offset = useMemo(() => mapOffset(searchOffset), [searchOffset]);

  const searchLimit = searchParams.get('limit');
  const limit = useMemo(() => mapLimit(searchLimit), [searchLimit]);

  const setPage = useCallback(
    (offset: number) => {
      setSearchParams((prev) => ({
        ...Object.fromEntries([...prev]),
        offset: offset.toString(),
      }));
      onChange?.();
    },
    [setSearchParams, onChange],
  );

  const setLimit = useCallback(
    (limit: number) => {
      setSearchParams((prev) => ({
        ...Object.fromEntries([...prev]),
        limit: limit.toString(),
        offset: MIN_OFFSET.toString(),
      }));
      onChange?.();
    },
    [setSearchParams, onChange],
  );

  return {
    offset,
    limit,
    setPage,
    setLimit,
  };
};
