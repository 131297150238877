import { KeysOfType } from '@global/types';
import type { ColumnDef, ColumnMeta, GroupColumnDef, Header } from '@tanstack/react-table';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Fragment, memo, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { useHorizontalScrollRef } from '../hooks/useHorizontalScrollRef';
import { alignStyles } from '../TableBase/constants';
import { RowHeader } from './RowHeader';
import './VerticalTable.css';

type VerticalTableProps<T extends object, TableMeta extends Record<string, unknown> = Record<string, never>> = {
  data: T[];
  columns: ColumnDef<T>[];
  tableMeta?: TableMeta;
  rowKey?: KeysOfType<T, string>;
} & (
  | { columns: ColumnDef<T>[]; withColumnGrouping?: never }
  | { columns: GroupColumnDef<T>[]; withColumnGrouping: true }
);

const VerticalTableComp = <T extends object, TableMeta extends Record<string, unknown> = Record<string, never>>({
  data,
  columns,
  tableMeta,
  rowKey,
  withColumnGrouping,
}: VerticalTableProps<T, TableMeta>) => {
  const { isScrollable, bodyRef } = useHorizontalScrollRef(data);

  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: rowKey ? (originalRow) => String(originalRow[rowKey]) : undefined,
    meta: {
      ...(tableMeta ?? {}),
    },
  });
  const headers = table.getLeafHeaders().filter((h) => (withColumnGrouping ? h.depth === 2 : h.depth === 1));
  const headerGroups = table.getHeaderGroups().find((hg) => hg.depth === 0)?.headers ?? [];
  const rowsCount = table.getRowModel().flatRows.length;

  const renderCells = useCallback(
    (index: number, rowId: string, isScrollable: boolean) => {
      const cells = [];
      for (let i = 0; i < rowsCount; i++) {
        const cell = table.getRowModel().flatRows[i].getVisibleCells()[index];
        const meta = cell.column.columnDef.meta as ColumnMeta<T, unknown>;
        const align = meta?.align ?? 'left';
        const isFirstRow = index === 0;

        cells.push(
          <td key={cell.id} data-key={cell.id} className={meta?.className?.(cell.getContext())}>
            <div
              className={twMerge(
                'relative flex h-full items-center gap-x-1 whitespace-break-spaces [overflow-wrap:anywhere]',
                alignStyles(align).cellClassName,
              )}
            >
              <div className="grow">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
              {(i < rowsCount - 1 || !isScrollable) && isFirstRow ? (
                <div className="absolute -right-[16px] h-[80%] w-0 shrink-0" />
              ) : null}
            </div>
          </td>,
        );
      }

      return cells;
    },
    [table, headers, rowsCount],
  );

  const getFlatSubHeaders = (header: Header<T, unknown>, result: Header<T, unknown>[] = []): Header<T, unknown>[] => {
    if (header.subHeaders.length > 0) {
      header.subHeaders.forEach((subHeader) => {
        getFlatSubHeaders(subHeader, result);
      });
    } else {
      result.push(header);
    }
    return result;
  };

  const renderRowHeader = (headerGroup: Header<T, unknown>, rowIndex: number, isFirstRow: boolean) => {
    const isLeaf = !headerGroup.subHeaders.length;

    if (isLeaf) {
      return (
        <Fragment>
          <RowHeader header={headerGroup} />
          {renderCells(headerGroup.index, headerGroup.id, isScrollable)}
        </Fragment>
      );
    }

    let groupedIndex = 0;

    const groupedSubHeaders = headerGroup.subHeaders.map((header) => {
      const value = {
        header,
        firstIndex: groupedIndex,
      };

      groupedIndex += header.subHeaders.length > 0 ? header.subHeaders.length : 1;

      return value;
    });

    const nextSubHeader = groupedSubHeaders.reduce((current, next) => {
      const currentDifference = rowIndex - current.firstIndex;
      const nextDifference = rowIndex - next.firstIndex;

      if (currentDifference === 0) return current;

      if (nextDifference >= 0) return next;

      return current;
    }, groupedSubHeaders[0]);

    return (
      <Fragment>
        {isFirstRow ? <RowHeader header={headerGroup} /> : null}
        {renderRowHeader(nextSubHeader.header, rowIndex, nextSubHeader.firstIndex === rowIndex)}
      </Fragment>
    );
  };

  return (
    <div ref={bodyRef} className="h-auto w-full overflow-auto">
      <table className="vertical-table" data-scrollable={isScrollable}>
        <tbody>
          {headerGroups.map((headerGroup) => {
            const headerGroupArray = getFlatSubHeaders(headerGroup);

            return headerGroupArray.map((header, index) => {
              const headerMeta = header.column.columnDef.meta as ColumnMeta<T, unknown>;

              return (
                <tr key={header.id} data-key={header.id} data-type={headerMeta?.rowType}>
                  {renderRowHeader(headerGroup, index, index === 0)}
                </tr>
              );
            });
          })}
        </tbody>
      </table>
    </div>
  );
};

export const VerticalTable = memo(VerticalTableComp) as typeof VerticalTableComp;
