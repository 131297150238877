export type Option<T> = {
  value: T;
  label: string;
};

export type KeysOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

export enum TargetMarkets {
  'B2B' = 'B2B',
  'B2C' = 'B2C',
}

export enum Tags {
  '3D Printing' = '3D Printing',
  '3D Technology' = '3D Technology',
  'A/B Testing' = 'A/B Testing',
  'Addiction Treatment' = 'Addiction Treatment',
  'Advanced Manufacturing' = 'Advanced Manufacturing',
  'Advanced Materials' = 'Advanced Materials',
  'Advertising Platforms' = 'Advertising Platforms',
  'Affiliate Marketing' = 'Affiliate Marketing',
  'Agritech' = 'Agritech',
  'AgTech' = 'AgTech',
  'Alternative Medicine' = 'Alternative Medicine',
  'Alternative Protein' = 'Alternative Protein',
  'Analytics' = 'Analytics',
  'Application Performance Management' = 'Application Performance Management',
  'Application Specific Integrated Circuit (ASIC)' = 'Application Specific Integrated Circuit (ASIC)',
  'Apps' = 'Apps',
  'Artificial Intelligence (AI)' = 'Artificial Intelligence (AI)',
  'Assisted Living' = 'Assisted Living',
  'Assistive Technology' = 'Assistive Technology',
  'AudioTech' = 'AudioTech',
  'Augmented Reality' = 'Augmented Reality',
  'Autonomous Vehicles' = 'Autonomous Vehicles',
  'B2B Payments' = 'B2B Payments',
  'Battery Technology' = 'Battery Technology',
  'Big Data' = 'Big Data',
  'Biofuel' = 'Biofuel',
  'Bioinformatics' = 'Bioinformatics',
  'Biomass Energy' = 'Biomass Energy',
  'Biometrics' = 'Biometrics',
  'Biopharma' = 'Biopharma',
  'Biotechnology' = 'Biotechnology',
  'Blockchain' = 'Blockchain',
  'Blogging Platforms' = 'Blogging Platforms',
  'Browser Extensions' = 'Browser Extensions',
  'Business Information Systems' = 'Business Information Systems',
  'Business Intelligence' = 'Business Intelligence',
  'Business Process Automation (BPA)' = 'Business Process Automation (BPA)',
  'Computer Assisted Design (CAD)' = 'Computer Assisted Design (CAD)',
  'Chatbot' = 'Chatbot',
  'CivicTech' = 'CivicTech',
  'CleanTech' = 'CleanTech',
  'ClimateTech' = 'ClimateTech',
  'Cloud Computing' = 'Cloud Computing',
  'Cloud Data Services' = 'Cloud Data Services',
  'Cloud Infrastructure' = 'Cloud Infrastructure',
  'Cloud Management' = 'Cloud Management',
  'Cloud Security' = 'Cloud Security',
  'Cloud Storage' = 'Cloud Storage',
  'CloudTech' = 'CloudTech',
  'Content Management System (CMS)' = 'Content Management System (CMS)',
  'Computer Vision' = 'Computer Vision',
  'Construction Technology' = 'Construction Technology',
  'Consumer Software' = 'Consumer Software',
  'CRM' = 'CRM',
  'Cyber Security' = 'Cyber Security',
  'Darknet' = 'Darknet',
  'Data Center' = 'Data Center',
  'Data Center Automation' = 'Data Center Automation',
  'Data Collection and Labeling' = 'Data Collection and Labeling',
  'Data Governance' = 'Data Governance',
  'Data Integration' = 'Data Integration',
  'Data Management' = 'Data Management',
  'Data Mining' = 'Data Mining',
  'Data Storage' = 'Data Storage',
  'Data Visualization' = 'Data Visualization',
  'Database' = 'Database',
  'Decentralized Finance (DeFi)' = 'Decentralized Finance (DeFi)',
  'Desktop Apps' = 'Desktop Apps',
  'Developer APIs' = 'Developer APIs',
  'Developer Platform' = 'Developer Platform',
  'Developer Tools' = 'Developer Tools',
  'DevOps' = 'DevOps',
  'Dietary Supplements' = 'Dietary Supplements',
  'Digital Entertainment' = 'Digital Entertainment',
  'Digital Health' = 'Digital Health',
  'Digital Marketing' = 'Digital Marketing',
  'Digital Media' = 'Digital Media',
  'Digital Signage' = 'Digital Signage',
  'Direct Marketing' = 'Direct Marketing',
  'Direct Sales' = 'Direct Sales',
  'DIY' = 'DIY',
  'Document Management' = 'Document Management',
  'Document Preparation' = 'Document Preparation',
  'Digital Rights Management (DRM)' = 'Digital Rights Management (DRM)',
  'Drone Management' = 'Drone Management',
  'Drones' = 'Drones',
  'Demand Side Platform (DSP)' = 'Demand Side Platform (DSP)',
  'E-Commerce Platforms' = 'E-Commerce Platforms',
  'E-Learning' = 'E-Learning',
  'E-Signature' = 'E-Signature',
  'EBooks' = 'EBooks',
  'Ediscovery' = 'Ediscovery',
  'EdTech' = 'EdTech',
  'Edutainment' = 'Edutainment',
  'Electric Vehicle' = 'Electric Vehicle',
  'Electronic Design Automation (EDA)' = 'Electronic Design Automation (EDA)',
  'Electronic Health Record (EHR)' = 'Electronic Health Record (EHR)',
  'Email Marketing' = 'Email Marketing',
  'Embedded Software' = 'Embedded Software',
  'Embedded Systems' = 'Embedded Systems',
  'Emergency Medicine' = 'Emergency Medicine',
  'Emerging Markets' = 'Emerging Markets',
  'Employee Benefits' = 'Employee Benefits',
  'Energy Efficiency' = 'Energy Efficiency',
  'Energy Management' = 'Energy Management',
  'Energy Storage' = 'Energy Storage',
  'Enterprise Applications' = 'Enterprise Applications',
  'Enterprise Resource Planning (ERP)' = 'Enterprise Resource Planning (ERP)',
  'Enterprise Software' = 'Enterprise Software',
  'Environmental Consulting' = 'Environmental Consulting',
  'Environmental Engineering' = 'Environmental Engineering',
  'Ephemeral Content' = 'Ephemeral Content',
  'Equestrian' = 'Equestrian',
  'eSports' = 'eSports',
  'Facial Recognition' = 'Facial Recognition',
  'Fast-Moving Consumer Goods' = 'Fast-Moving Consumer Goods',
  'Ferry Service' = 'Ferry Service',
  'Fertility' = 'Fertility',
  'Field Support' = 'Field Support',
  'Field-Programmable Gate Array (FPGA)' = 'Field-Programmable Gate Array (FPGA)',
  'File Sharing' = 'File Sharing',
  'FinTech' = 'FinTech',
  'Flash Sale' = 'Flash Sale',
  'Flash Storage' = 'Flash Storage',
  'Fleet Management' = 'Fleet Management',
  'FoodTech' = 'FoodTech',
  'Fraud Detection' = 'Fraud Detection',
  'Freemium' = 'Freemium',
  'Freight Service' = 'Freight Service',
  'Funding Platform' = 'Funding Platform',
  'Gamification' = 'Gamification',
  'Generative AI' = 'Generative AI',
  'Genetics' = 'Genetics',
  'Geospatial' = 'Geospatial',
  'Geothermal Energy' = 'Geothermal Energy',
  'GovTech' = 'GovTech',
  'GPS' = 'GPS',
  'Graphics Processing Unit (GPU)' = 'Graphics Processing Unit (GPU)',
  'GreenTech' = 'GreenTech',
  'Hardware' = 'Hardware',
  'HealthTech' = 'HealthTech',
  'Hydroelectric' = 'Hydroelectric',
  'Hydroponics' = 'Hydroponics',
  'IaaS' = 'IaaS',
  'Identity Management' = 'Identity Management',
  'Image Recognition' = 'Image Recognition',
  'Impact Investing' = 'Impact Investing',
  'InsurTech' = 'InsurTech',
  'Internet of Things' = 'Internet of Things',
  'Internet Radio' = 'Internet Radio',
  'Intrusion Detection' = 'Intrusion Detection',
  'Knowledge Management' = 'Knowledge Management',
  'Landscaping' = 'Landscaping',
  'Laser' = 'Laser',
  'Last Mile Transportation' = 'Last Mile Transportation',
  'Lead Generation' = 'Lead Generation',
  'Lead Management' = 'Lead Management',
  'LegalTech' = 'LegalTech',
  'Machine Learning' = 'Machine Learning',
  'Management Information Systems' = 'Management Information Systems',
  'Marine Technology' = 'Marine Technology',
  'Marketing Automation' = 'Marketing Automation',
  'Medical Device' = 'Medical Device',
  'Meeting Software' = 'Meeting Software',
  'Metaverse' = 'Metaverse',
  'mHealth' = 'mHealth',
  'Micro Lending' = 'Micro Lending',
  'Micro-mobility' = 'Micro-mobility',
  'Mining Technology' = 'Mining Technology',
  'MMO Games' = 'MMO Games',
  'Mobile Advertising' = 'Mobile Advertising',
  'Mobile Apps' = 'Mobile Apps',
  'Mobile Devices' = 'Mobile Devices',
  'Mobile Payments' = 'Mobile Payments',
  'Mobility tech' = 'Mobility tech',
  'MOOC' = 'MOOC',
  'Mortgage tech' = 'Mortgage tech',
  'Motion Capture' = 'Motion Capture',
  'Nanotechnology' = 'Nanotechnology',
  'Natural Language Processing' = 'Natural Language Processing',
  'Navigation' = 'Navigation',
  'Network Hardware' = 'Network Hardware',
  'Network Security' = 'Network Security',
  'Neuroscience' = 'Neuroscience',
  'Non-Fungible Token (NFT)' = 'Non-Fungible Token (NFT)',
  'Open Source' = 'Open Source',
  'Operating Systems' = 'Operating Systems',
  'Optical Communication' = 'Optical Communication',
  'Organic' = 'Organic',
  'Organic Food' = 'Organic Food',
  'Personalization' = 'Personalization',
  'Pet tech' = 'Pet tech',
  'Plant-Based Foods' = 'Plant-Based Foods',
  'Playstation' = 'Playstation',
  'Podcast' = 'Podcast',
  'Point of Sale' = 'Point of Sale',
  'Power Grid' = 'Power Grid',
  'Precision Medicine' = 'Precision Medicine',
  'Predictive Analytics' = 'Predictive Analytics',
  'Prepaid Cards' = 'Prepaid Cards',
  'Presentation Software' = 'Presentation Software',
  'Product Design' = 'Product Design',
  'Product Management' = 'Product Management',
  'Product Research' = 'Product Research',
  'Product Search' = 'Product Search',
  'Productivity Tools' = 'Productivity Tools',
  'Professional Networking' = 'Professional Networking',
  'PropTech' = 'PropTech',
  'QR Codes' = 'QR Codes',
  'Quantum Computing' = 'Quantum Computing',
  'Real Estate Tech' = 'Real Estate Tech',
  'Remote Sensing' = 'Remote Sensing',
  'Restaurant Tech' = 'Restaurant Tech',
  'Retail Technology' = 'Retail Technology',
  'RFID' = 'RFID',
  'RISC' = 'RISC',
  'Robotic Process Automation (RPA)' = 'Robotic Process Automation (RPA)',
  'Robotics' = 'Robotics',
  'Sales Automation' = 'Sales Automation',
  'Sales Enablement' = 'Sales Enablement',
  'Same Day Delivery' = 'Same Day Delivery',
  'Satellite Communication' = 'Satellite Communication',
  'Search Engine' = 'Search Engine',
  'Semantic Search' = 'Semantic Search',
  'Semantic Web' = 'Semantic Web',
  'Sensor' = 'Sensor',
  'SEO' = 'SEO',
  'Serious Games' = 'Serious Games',
  'Sex Tech' = 'Sex Tech',
  'Sharing Economy' = 'Sharing Economy',
  'Smart Building' = 'Smart Building',
  'Smart Cities' = 'Smart Cities',
  'Smart Contracts' = 'Smart Contracts',
  'Smart Home' = 'Smart Home',
  'Software' = 'Software',
  'Space Tech' = 'Space Tech',
  'Speech Recognition' = 'Speech Recognition',
  'Supply Chain Technology' = 'Supply Chain Technology',
  'Telehealth' = 'Telehealth',
  'Trading Platform' = 'Trading Platform',
  'Transaction Processing' = 'Transaction Processing',
  'Virtual Assistant' = 'Virtual Assistant',
  'Virtual Currency' = 'Virtual Currency',
  'Virtual Desktop' = 'Virtual Desktop',
  'Virtual Goods' = 'Virtual Goods',
  'Virtual Reality' = 'Virtual Reality',
  'Virtual Workforce' = 'Virtual Workforce',
  'Virtual World' = 'Virtual World',
  'Virtualization' = 'Virtualization',
  'Visual Search' = 'Visual Search',
  'Warehouse Automation' = 'Warehouse Automation',
  'Wearables' = 'Wearables',
  'Web Apps' = 'Web Apps',
  'Web Browsers' = 'Web Browsers',
  'Web Design' = 'Web Design',
  'Web Development' = 'Web Development',
  'Web Hosting' = 'Web Hosting',
  'Web3' = 'Web3',
  'Wind Energy' = 'Wind Energy',
  '3D Printed Buildings' = '3D Printed Buildings',
  'Autonomous Flight' = 'Autonomous Flight',
  'Autonomous Shipping' = 'Autonomous Shipping',
  'Autonomous Trucking' = 'Autonomous Trucking',
  'Blockchain Real Estate' = 'Blockchain Real Estate',
  'Cannabis Breathalyzers' = 'Cannabis Breathalyzers',
  'Climate Risk Modeling As A Service' = 'Climate Risk Modeling As A Service',
  'Construction Robotics' = 'Construction Robotics',
  'Digital Freight Brokerage' = 'Digital Freight Brokerage',
  'Electric Flight' = 'Electric Flight',
  'Food Service Robots And Machines' = 'Food Service Robots And Machines',
  'Ghost Kitchens' = 'Ghost Kitchens',
  'Industrial Workplace Safety' = 'Industrial Workplace Safety',
  'In-Space Manufacturing' = 'In-Space Manufacturing',
  'Low-Power Wide-Area Networks (Lpwan)' = 'Low-Power Wide-Area Networks (Lpwan)',
  'Microweather' = 'Microweather',
  'Modular Construction' = 'Modular Construction',
  'Natural Disaster Preparedness and Response' = 'Natural Disaster Preparedness and Response',
  'Ocean Data Collection' = 'Ocean Data Collection',
  'Resilient Positioning, Navigation, And Timing (PNT)' = 'Resilient Positioning, Navigation, And Timing (PNT)',
  'Small Satellites' = 'Small Satellites',
  'Smart Mirrors' = 'Smart Mirrors',
  'Smart Packaging' = 'Smart Packaging',
  'Sports Tech' = 'Sports Tech',
  'Supersonic Travel' = 'Supersonic Travel',
  'Sustainable Packaging' = 'Sustainable Packaging',
  'Urban Planning Tech' = 'Urban Planning Tech',
  'Virtual Events' = 'Virtual Events',
  'Warehouse Management Tech' = 'Warehouse Management Tech',
  '3D Printed Foods' = '3D Printed Foods',
  'Ai-Enhanced Learning' = 'Ai-Enhanced Learning',
  'Air Taxis' = 'Air Taxis',
  'Art Trading Platforms' = 'Art Trading Platforms',
  'Auto Commerce' = 'Auto Commerce',
  'Cannabis Beverages' = 'Cannabis Beverages',
  'Cashierless Checkouts' = 'Cashierless Checkouts',
  'Clean Meat' = 'Clean Meat',
  'Connected Fitness Equipment' = 'Connected Fitness Equipment',
  'End-Of-Life Planning' = 'End-Of-Life Planning',
  'Hyperloop' = 'Hyperloop',
  'Income Share Agreements (Isa) Education' = 'Income Share Agreements (Isa) Education',
  'Insect-Based Foods' = 'Insect-Based Foods',
  'Smart Clothing' = 'Smart Clothing',
  'Smart Home Assistants' = 'Smart Home Assistants',
  'Smart Locks' = 'Smart Locks',
  'Smart Jewelry' = 'Smart Jewelry',
  'Social Audio' = 'Social Audio',
  'Sustainable Fashion' = 'Sustainable Fashion',
  'Sustainable Tourism' = 'Sustainable Tourism',
  'Batteryless Iot Sensors' = 'Batteryless Iot Sensors',
  'Concentrated Solar Power' = 'Concentrated Solar Power',
  'Electric Vehicle Charging Infrastructure' = 'Electric Vehicle Charging Infrastructure',
  'Fusion Energy' = 'Fusion Energy',
  'Hydrogen Energy' = 'Hydrogen Energy',
  'Lithium Extraction Technology' = 'Lithium Extraction Technology',
  'Long-Duration Energy Storage' = 'Long-Duration Energy Storage',
  'Renewable Ocean Energy' = 'Renewable Ocean Energy',
  'Smart Grid' = 'Smart Grid',
  'Small Modular Reactors' = 'Small Modular Reactors',
  'Waste-To-Energy' = 'Waste-To-Energy',
  'Alternative Home Financing' = 'Alternative Home Financing',
  'Banking As A Service' = 'Banking As A Service',
  'Conversational Banking' = 'Conversational Banking',
  'Microinsurance' = 'Microinsurance',
  'Real Estate Crowdfunding' = 'Real Estate Crowdfunding',
  'Security Deposit Alternatives' = 'Security Deposit Alternatives',
  'Youth Banking' = 'Youth Banking',
  'AI-Powered Drug Discovery' = 'AI-Powered Drug Discovery',
  'Anti-Aging' = 'Anti-Aging',
  'Assistive Tech' = 'Assistive Tech',
  'Crispr Diagnostics' = 'Crispr Diagnostics',
  'Fertility Tech' = 'Fertility Tech',
  'Gene Therapies' = 'Gene Therapies',
  'Medical Exoskeletons And Prosthetics' = 'Medical Exoskeletons And Prosthetics',
  'Medical Robotics' = 'Medical Robotics',
  'Mental Health Tech' = 'Mental Health Tech',
  'Nanomedicine' = 'Nanomedicine',
  'Neurotechnology' = 'Neurotechnology',
  'Psychedelics' = 'Psychedelics',
  'Sleep Tech' = 'Sleep Tech',
  'Vr Health' = 'Vr Health',
  '4D Printing' = '4D Printing',
  'Aiops' = 'Aiops',
  'Autonomous Delivery' = 'Autonomous Delivery',
  'Autonomous Vehicle Simulation' = 'Autonomous Vehicle Simulation',
  'Blockchain Gaming' = 'Blockchain Gaming',
  'Cloud Gaming' = 'Cloud Gaming',
  'Cloud Workload Protection' = 'Cloud Workload Protection',
  'Cognitive Computing' = 'Cognitive Computing',
  'Computational Storage' = 'Computational Storage',
  'Contract Management Automation' = 'Contract Management Automation',
  'Crowdsourced Testing' = 'Crowdsourced Testing',
  'Decentralized Autonomous Organization (Dao)' = 'Decentralized Autonomous Organization (Dao)',
  'Data Center Cooling Tech' = 'Data Center Cooling Tech',
  'Database Sharding' = 'Database Sharding',
  'Digital Avatars' = 'Digital Avatars',
  'Digital Twins' = 'Digital Twins',
  'DevSecOps' = 'DevSecOps',
  'Dna Data Storage' = 'Dna Data Storage',
  'Edge Computing Semiconductors' = 'Edge Computing Semiconductors',
  'Finops' = 'Finops',
  'Graph Databases And Analytics' = 'Graph Databases And Analytics',
  'IoT Security' = 'IoT Security',
  'Lidar' = 'Lidar',
  'Low-Code And No-Code Development Platforms' = 'Low-Code And No-Code Development Platforms',
  'Next-Gen Network Security' = 'Next-Gen Network Security',
  'Passwordless Authentication' = 'Passwordless Authentication',
  'Post-Quantum Cryptography' = 'Post-Quantum Cryptography',
  'Security Orchestration, Automation And Response (Soar)' = 'Security Orchestration, Automation And Response (Soar)',
  'Service Mesh' = 'Service Mesh',
  'Silicon Photonics' = 'Silicon Photonics',
  'Swarm AI' = 'Swarm AI',
  'TinyML (Tiny Machine Learning)' = 'TinyML (Tiny Machine Learning)',
  'Vehicle-To-Everything (V2X)' = 'Vehicle-To-Everything (V2X)',
  'Materials And Resources' = 'Materials And Resources',
  'Cellular Agriculture' = 'Cellular Agriculture',
  'Desalination Tech' = 'Desalination Tech',
  'Indoor Farming' = 'Indoor Farming',
  'Lithium Ion Battery Recycling' = 'Lithium Ion Battery Recycling',
  'Livestock Health' = 'Livestock Health',
  'Mining Tech' = 'Mining Tech',
  'Pollination Tech' = 'Pollination Tech',
  'Reforestation' = 'Reforestation',
  'Regenerative Agriculture' = 'Regenerative Agriculture',
  'Smart Waste Management' = 'Smart Waste Management',
}

export enum ItalianRegions {
  'Valle d’Aosta' = 'Valle d’Aosta',
  'Piemonte' = 'Piemonte',
  'Liguria' = 'Liguria',
  'Lombardia' = 'Lombardia',
  'Trentino Alto Adige' = 'Trentino Alto Adige',
  'Veneto' = 'Veneto',
  'Friuli-Venezia Giulia' = 'Friuli-Venezia Giulia',
  'Emilia Romagna' = 'Emilia Romagna',
  'Toscana' = 'Toscana',
  'Marche' = 'Marche',
  'Umbria' = 'Umbria',
  'Abruzzo' = 'Abruzzo',
  'Lazio' = 'Lazio',
  'Molise' = 'Molise',
  'Campania' = 'Campania',
  'Basilicata' = 'Basilicata',
  'Puglia' = 'Puglia',
  'Calabria' = 'Calabria',
  'Sicilia' = 'Sicilia',
  'Sardegna' = 'Sardegna',
}
