import { formatNumber } from '@utils';

export const formatNFP = (input: number) => {
  if (input >= 0) {
    return formatNumber(input);
  }

  const value = formatNumber(Math.abs(input));

  return `(${value})`;
};
