import { api } from '@/api/apiV2';
import { QueryKeys } from '@/api/entities/queryKeys';
import { FinancialInsightsResponse } from '@/api/generated/Api';
import { useQuery } from '@tanstack/react-query';

type UseCompanyFinancialArgs = {
  companyId: string;
  signal?: AbortSignal;
};

export const useCompanyFinancial = ({ companyId, signal }: UseCompanyFinancialArgs) => {
  const { data, isFetching } = useQuery<FinancialInsightsResponse[]>({
    queryKey: [QueryKeys.CompanyFinancial, companyId],
    queryFn: async () => {
      const { data } = await api.company.companiesFinancialInsightsDetail(companyId, {
        signal,
      });

      return data;
    },
  });

  return {
    financialData: data,
    isFinancialDataLoading: isFetching,
  };
};
