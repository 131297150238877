import { CURRENT_YEAR } from '@global/constants/shared';
import { DEFAULT_SEARCH_FILTERS, useSearchFiltersStore } from '@global/searchFilters';
import { Slider } from '@mui/material';
import { FC, useCallback } from 'react';
import { FilterCard } from '../common';
import { SliderOnChangeFunc } from '../types';

export const FoundingDateFilter: FC = () => {
  const founded = useSearchFiltersStore((state) => state.founded);
  const setFounded = useSearchFiltersStore((state) => state.actions.setFounded);

  const handleFoundingDateFilter = useCallback<SliderOnChangeFunc>(
    (_, newValue) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      setFounded([newValue[0], newValue[1]]);
    },
    [setFounded],
  );

  return (
    <FilterCard label={{ text: 'foundingDate' }}>
      <div>
        <Slider
          getAriaLabel={() => 'Founding Date slider'}
          value={founded}
          onChange={handleFoundingDateFilter}
          valueLabelDisplay="auto"
          min={1900}
          max={CURRENT_YEAR}
          disableSwap
        />
        {/* Range */}
        <div className="flex items-center justify-between text-[12px] dark:text-slate-600">
          <span>{founded[0] || DEFAULT_SEARCH_FILTERS.founded[0]}</span>
          <span>{founded[1] || DEFAULT_SEARCH_FILTERS.founded[1]}</span>
        </div>
      </div>
    </FilterCard>
  );
};
