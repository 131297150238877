import { useAbortController } from '@/hooks';
import { VerticalTable } from '@components/Tables';
import { Loadable } from '@global/components/Loadable';
import { FC } from 'react';
import { FINANCIAL_INSIGHTS_TABLE_COLUMNS } from './constants';
import { useCompanyFinancial } from './useCompanyFinancial';

type FinancialInsightsTableProps = {
  companyId: string;
};

export const FinancialInsightsTable: FC<FinancialInsightsTableProps> = ({ companyId }) => {
  const { signal } = useAbortController();

  const { financialData, isFinancialDataLoading } = useCompanyFinancial({ companyId });

  return (
    <Loadable isLoading={isFinancialDataLoading}>
      {financialData && financialData?.length > 0 ? (
        <VerticalTable data={financialData} columns={FINANCIAL_INSIGHTS_TABLE_COLUMNS} />
      ) : (
        <div className="italic">No financial info</div>
      )}
    </Loadable>
  );
};
