import { FinancialValue } from '@/api/generated/Api';
import { Typography } from '@components/Typography';
import { formatNumber, formatPercentage } from '@utils';
import { FC } from 'react';

type FinancialInsightsTableCellProps = {
  item: FinancialValue;
};

export const FinancialInsightsTableCell: FC<FinancialInsightsTableCellProps> = ({ item }) => {
  return (
    <div className="flex flex-col items-center gap-2 *:text-grey-900">
      <Typography arbitrary text={formatNumber(item.value ?? 0)} variant="xs/bold" className="uppercase" />
      <Typography arbitrary text={formatPercentage(item.percentage)} variant="xs/regular" />
    </div>
  );
};
