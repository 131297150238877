import { Filter } from '@/api/generated/Api';
import {
  COMPANY_ENTITY_TYPES,
  COMPANY_SIZE_OPTIONS,
  ITALIAN_REGIONS_OPTIONS,
  LATEST_FUNDING_TYPE_OPTIONS,
  RAW_COMPANY_TYPE_OPTIONS,
  REGION_OPTIONS,
  TAGS_OPTIONS,
  TARGET_MARKETS_OPTIONS,
} from '@/data/options';
import { mapLimit, mapOffset } from '@components/Pagination';
import { PaginationQueryArgs } from '@components/Pagination/types';
import { Option } from '@global/types';
import { getCurrentQueryParams } from '@utils/queryParams';
import { StateCreator } from 'zustand';
import { DEFAULT_SEARCH_FILTERS } from './constants';
import { SearchFiltersValues } from './types';

type ZustandStoreInitializer<T> = Parameters<StateCreator<T, [], []>>;
type InitializerSet<T> = ZustandStoreInitializer<T>[0];
type InitializerGet<T> = ZustandStoreInitializer<T>[1];

export const toggleStoreMultiSelect = <T extends string | number>(
  get: InitializerGet<SearchFiltersValues>,
  field: Extract<
    keyof SearchFiltersValues,
    'countries' | 'companySizes' | 'foundingStages' | 'tags' | 'targetMarkets' | 'italianRegions'
  >,
  item: Option<T>,
) => {
  const newValue = [...get()[field]] as Option<T>[];
  const index = newValue.findIndex((option) => option.value === item.value);

  if (index !== -1) {
    newValue.splice(index, 1);
  } else {
    newValue.push(item);
  }

  return newValue;
};

export const mapFilterToStore = (filters: Filter, query: string) =>
  ({
    ...DEFAULT_SEARCH_FILTERS,
    query,
    isRegularSearch: !filters.isVectorSearch,
    scoreFrom: (filters.scoreFrom ?? DEFAULT_SEARCH_FILTERS.scoreFrom) * 100,
    companySizes: COMPANY_SIZE_OPTIONS.filter((option) => filters.companySize.includes(option.label)),
    countries:
      REGION_OPTIONS.filter((option) => filters.countries.includes(option.value)) ?? DEFAULT_SEARCH_FILTERS.countries,
    founded: [
      filters.foundedFrom ?? DEFAULT_SEARCH_FILTERS.founded[0],
      filters.foundedTo ?? DEFAULT_SEARCH_FILTERS.founded[1],
    ],
    funding: filters.isFoundingInfoExist,
    foundingStages: LATEST_FUNDING_TYPE_OPTIONS.filter((option) => filters.foundingStages.includes(option.label)),
    tags: TAGS_OPTIONS.filter((option) => filters.tags.includes(option.label.replace(/\s/g, ''))),
    companyType:
      RAW_COMPANY_TYPE_OPTIONS.find((option) => option.value === filters.entityTypeL2) ??
      DEFAULT_SEARCH_FILTERS.companyType,
    targetMarkets: TARGET_MARKETS_OPTIONS.filter((option) => filters.targetMarkets.includes(option.label)),
    filterType: filters.isItalianSearch ? 'Italy' : 'Worldwide',
    revenue: [
      filters.italianFilter?.revenueFrom ?? DEFAULT_SEARCH_FILTERS.revenue[0],
      filters.italianFilter?.revenueTo ?? DEFAULT_SEARCH_FILTERS.revenue[1],
    ],
    EBITDA: [
      filters.italianFilter?.ebitdaFrom ?? DEFAULT_SEARCH_FILTERS.EBITDA[0],
      filters.italianFilter?.ebitdaTo ?? DEFAULT_SEARCH_FILTERS.EBITDA[1],
    ],
    NFP: [
      filters.italianFilter?.nfpFrom ?? DEFAULT_SEARCH_FILTERS.NFP[0],
      filters.italianFilter?.nfpTo ?? DEFAULT_SEARCH_FILTERS.NFP[1],
    ],
    EBITDAMargin: [
      filters.italianFilter?.ebitdaMarginFrom ?? DEFAULT_SEARCH_FILTERS.EBITDAMargin[0],
      filters.italianFilter?.ebitdaMarginTo ?? DEFAULT_SEARCH_FILTERS.EBITDAMargin[1],
    ],
    numberOfShareholders: [
      filters.italianFilter?.countOfShareholdersFrom ?? DEFAULT_SEARCH_FILTERS.numberOfShareholders[0],
      filters.italianFilter?.countOfShareholdersTo ?? DEFAULT_SEARCH_FILTERS.numberOfShareholders[1],
    ],
    shareholderAge: [
      filters.italianFilter?.majorityShareholderFrom ?? DEFAULT_SEARCH_FILTERS.shareholderAge[0],
      filters.italianFilter?.majorityShareholderTo ?? DEFAULT_SEARCH_FILTERS.shareholderAge[1],
    ],
    italianRegions: ITALIAN_REGIONS_OPTIONS.filter((option) =>
      filters.italianFilter?.italianRegions.includes(option.value),
    ),
  }) satisfies SearchFiltersValues;

export const mapFiltersToSearchRequest = ({
  query,
  isRegularSearch,
  founded,
  scoreFrom,
  filterType,
  countries,
  companySizes,
  funding,
  foundingStages,
  tags,
  companyType,
  targetMarkets,
  revenue,
  EBITDA,
  NFP,
  EBITDAMargin,
  numberOfShareholders,
  shareholderAge,
  italianRegions,
}: SearchFiltersValues) => {
  const { limit, offset } = getCurrentQueryParams<PaginationQueryArgs>();

  const limitParam = mapLimit(limit);
  const offsetParam = mapOffset(offset);

  return {
    limit: limitParam,
    offset: offsetParam * limitParam,
    query,
    isVectorSearch: !isRegularSearch,
    foundedFrom: founded[0] === DEFAULT_SEARCH_FILTERS.founded[0] ? null : founded[0],
    foundedTo: founded[1] === DEFAULT_SEARCH_FILTERS.founded[1] ? null : founded[1],
    scoreFrom: scoreFrom !== 0 ? scoreFrom / 100 : undefined,
    countries: countries.length > 0 ? countries.map((option) => option.value) : [],
    companySize: companySizes.length > 0 ? companySizes.map((item) => item.label) : [],
    isFoundingInfoExist: funding,
    foundingStages: foundingStages.length > 0 ? foundingStages.map((item) => item.label) : [],
    tags: tags?.length > 0 ? tags?.map((item) => item?.label.replace(/\s/g, '')) : [],
    entityType:
      companyType?.value && COMPANY_ENTITY_TYPES.includes(companyType?.value) ? 'Company' : companyType?.value,
    entityTypeL2: companyType?.value && COMPANY_ENTITY_TYPES.includes(companyType?.value) ? companyType?.value : null,
    targetMarkets: targetMarkets?.length > 0 ? targetMarkets?.map((item) => item?.label) : [],
    isItalianSearch: filterType === 'Italy',
    italianFilter: {
      revenueFrom: revenue[0] === DEFAULT_SEARCH_FILTERS.revenue[0] ? null : revenue[0],
      revenueTo: revenue[1] === DEFAULT_SEARCH_FILTERS.revenue[1] ? null : revenue[1],
      ebitdaFrom: EBITDA[0] === DEFAULT_SEARCH_FILTERS.EBITDA[0] ? null : EBITDA[0],
      ebitdaTo: EBITDA[1] === DEFAULT_SEARCH_FILTERS.EBITDA[1] ? null : EBITDA[1],
      nfpFrom: NFP[0] === DEFAULT_SEARCH_FILTERS.NFP[0] ? null : NFP[0],
      nfpTo: NFP[1] === DEFAULT_SEARCH_FILTERS.NFP[1] ? null : NFP[1],
      ebitdaMarginFrom: EBITDAMargin[0] === DEFAULT_SEARCH_FILTERS.EBITDAMargin[0] ? null : EBITDAMargin[0],
      ebitdaMarginTo: EBITDAMargin[1] === DEFAULT_SEARCH_FILTERS.EBITDAMargin[1] ? null : EBITDAMargin[1],
      countOfShareholdersFrom:
        numberOfShareholders[0] === DEFAULT_SEARCH_FILTERS.numberOfShareholders[0] ? null : numberOfShareholders[0],
      countOfShareholdersTo:
        numberOfShareholders[1] === DEFAULT_SEARCH_FILTERS.numberOfShareholders[1] ? null : numberOfShareholders[1],
      majorityShareholderFrom:
        shareholderAge[0] === DEFAULT_SEARCH_FILTERS.shareholderAge[0] ? null : shareholderAge[0],
      majorityShareholderTo: shareholderAge[1] === DEFAULT_SEARCH_FILTERS.shareholderAge[1] ? null : shareholderAge[1],
      italianRegions: italianRegions.map((option) => option.value) ?? [],
    },
  } satisfies Filter;
};
