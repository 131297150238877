import { useQueries } from '@/api/entities/queries/useQueries';
import { QueryKeys } from '@/api/entities/queryKeys';
import { useUserLists } from '@/api/entities/userLists';
import { SignalsIcon } from '@components/SvgIcon/Icons';
import { Typography } from '@components/Typography';
import { useSearchFiltersStore } from '@global/searchFilters';
import { mapFilterToStore } from '@global/searchFilters/helpers';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { httpDeleteQuery } from '../../api/QueriesAPI';
import { httpGetUserLists } from '../../api/UserListsAPI';
import CreateListPopup from '../../components/CreateListPopup';
import { formatDateToDDMMYYYY } from '../../lib/utils';
import { DeleteListButton, EditListModal, EditQueryModal } from './parts';

const MyArea = () => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [selectedQueries, setSelectedQueries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedQuery, setSelectedQuery] = useState(null);
  const handleCloseQueryModal = () => setSelectedQuery(null);

  const [selectedList, setSelectedList] = useState(null);
  const handleCloseUserListModal = () => setSelectedList(null);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const abortController = useRef(new AbortController());

  useEffect(() => {
    abortController.current = new AbortController();

    return () => {
      abortController.current.abort();
    };
  }, []);

  const { queries } = useQueries({
    signal: abortController.current.signal,
  });

  const { userLists } = useUserLists({
    signal: abortController.current.signal,
  });

  const handleClick = (event, path) => {
    event.stopPropagation();
    event.preventDefault();

    const baseUrl = import.meta.env.VITE_ENV === 'noprod' ? 'https://app.internal.yunoai.io' : 'https://app.yunoai.io';
    const url = `${baseUrl}${path}`;

    const openInNewTab = event.metaKey || event.ctrlKey || event.button === 1;

    if (openInNewTab) {
      window.open(url, '_blank');
    } else {
      navigate(path);
    }
  };

  const resetFilters = useSearchFiltersStore((state) => state.actions.resetValues);

  const go = (event, query) => {
    event.preventDefault();

    const filters = mapFilterToStore(query.filter, query.query);
    resetFilters(filters);

    navigate(`/search`);
  };

  return (
    <div className="flex w-full flex-col gap-7">
      {isModalOpen && <CreateListPopup handleModalToggle={handleModalToggle} fetchLists={httpGetUserLists} />}

      <h1 className="text-3xl font-semibold">Lists & Searches</h1>

      <div className="flex w-full flex-col gap-4 md:flex-row">
        {/* Lists */}
        <div className="flex h-fit max-h-[70vh] min-h-[100px] w-full flex-col gap-2 rounded-md bg-base-200 p-3 lg:w-1/2">
          <div className="flex w-full items-center justify-between">
            <div className="text-xl font-semibold">My Lists</div>

            {/* Manipulate List*/}
            <div className="flex items-center justify-end gap-3">
              {/* Add */}
              <button
                onClick={() => setIsModalOpen(true)}
                className="flex w-full items-center text-base-content transition-transform hover:scale-102"
              >
                <FiPlusCircle size="24" className="cursor-pointer duration-500" />
              </button>
            </div>
          </div>

          {userLists?.length === 0 ? (
            <div className="italic text-base-content">No Lists yet</div>
          ) : (
            <div className="flex w-full flex-col gap-2 overflow-x-auto rounded-md">
              <table className="table table-pin-rows">
                <thead className="bg-base-200 uppercase">
                  <tr className="bg-base-200 shadow-md">
                    <th className="bg-base-200 px-6 py-3">Name List</th>
                    <th className="bg-base-200 px-6 py-3">Companies</th>
                    <th className="bg-base-200 px-6 py-3">Last Update</th>
                    <th className="bg-base-200 px-6 py-3">Notes</th>
                    <th className="bg-base-200 p-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userLists?.map((list) => (
                    <tr
                      key={list.id}
                      className="border-b-1 cursor-pointer border-gray-200 hover:bg-base-100"
                      onClick={(event) => handleClick(event, `/my-lists/${list.id}?from=my-area`)}
                    >
                      <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '30px' }}>
                        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">{list.name}</div>
                      </td>
                      <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '30px' }}>
                        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                          {list.companiesCount || '-'}
                        </div>
                      </td>
                      <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '30px' }}>
                        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                          {list.updatedAt ? formatDateToDDMMYYYY(list.updatedAt) : '-'}
                        </div>
                      </td>
                      <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '30px' }}>
                        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                          {list.notes.length > 30 ? `${list.notes.substring(0, 30)}...` : list.notes}
                        </div>
                      </td>
                      <td
                        aria-selected={selectedList === list}
                        className="group/cell flex items-center gap-2 overflow-hidden px-6 py-3"
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedList(list);
                          }}
                          className="group rounded-md px-2 py-1 duration-300 group-aria-selected/cell:bg-primary-300 hover:bg-primary-300 motion-safe:transition-colors"
                        >
                          <Typography
                            text="editList"
                            variant="xs/semibold"
                            className="capitalize text-grey duration-300 group-aria-selected/cell:text-base-200 group-hover:text-base-200 motion-safe:transition-colors"
                          />
                        </button>
                        <DeleteListButton id={list.id} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Searches */}
        <div className="flex h-fit max-h-[70vh] min-h-[100px] w-full flex-col gap-2 rounded-md bg-base-200 p-3 lg:w-1/2">
          <div className="flex w-full items-center justify-between">
            <div className="text-xl font-semibold">My Searches</div>

            {/* Delete Queries */}
            <div className={`${selectedQueries.length > 0 ? 'block' : 'hidden'} flex items-center justify-end gap-3`}>
              <button
                className="btn btn-sm rounded-md bg-primary-500 px-3 py-2 font-medium text-white"
                onClick={async () => {
                  for (let id of selectedQueries) {
                    httpDeleteQuery(id).catch((error) => {
                      console.error(error);
                    });
                  }
                  await queryClient.invalidateQueries({
                    queryKey: [QueryKeys.Queries],
                  });
                  setSelectedQueries([]);
                }}
              >
                Delete {selectedQueries.length} query
              </button>
            </div>
          </div>

          {/* Searches Table */}
          {queries.length === 0 ? (
            <div className="italic text-base-content">No Saved Searches yet</div>
          ) : (
            <div className="flex w-full flex-col gap-2 overflow-x-auto rounded-md">
              <table className="table table-pin-rows">
                <thead className="bg-base-200 uppercase">
                  <tr className="bg-base-200 uppercase shadow-md">
                    <th className="bg-base-200 px-6 py-3"></th>
                    <th className="bg-base-200 px-6 py-3">Query</th>
                    <th className="bg-base-200 p-0"></th>
                    <th className="bg-base-200 px-6 py-3">Companies</th>
                    <th className="bg-base-200 px-6 py-3">Date Saved</th>
                    <th className="bg-base-200 p-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {queries.map((query) => (
                    <tr
                      key={query.id}
                      className="border-b-1 cursor-pointer border-gray-200 hover:bg-base-100"
                      onClick={(event) => go(event, query)}
                    >
                      <td
                        className="w-4 p-4"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          type="checkbox"
                          className="checkbox-primary checkbox h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
                          checked={selectedQueries.includes(query.id)}
                          onChange={() => {
                            setSelectedQueries((prevState) => {
                              if (prevState.includes(query.id)) {
                                return prevState.filter((id) => id !== query.id);
                              } else {
                                return [...prevState, query.id];
                              }
                            });
                          }}
                        />
                      </td>
                      <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '30px' }}>
                        <div className="overflow-hidden overflow-ellipsis">
                          {query.title
                            ? query.title
                            : query.query.length > 200
                              ? `${query.query.substring(0, 200)}...`
                              : query.query}
                        </div>
                      </td>
                      <td className="p-0">
                        {query.hasSignals ? <SignalsIcon className="size-6 stroke-grey-700" /> : null}
                      </td>
                      <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '30px' }}>
                        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                          {query.countOfCompanies}
                        </div>
                      </td>
                      <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '30px' }}>
                        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                          {query.createdAt ? formatDateToDDMMYYYY(query.createdAt) : '-'}
                        </div>
                      </td>
                      <td
                        aria-selected={selectedQuery === query}
                        className="group/cell overflow-hidden px-6 py-3"
                        style={{ maxHeight: '30px' }}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedQuery(query);
                          }}
                          className="group rounded-md px-2 py-1 duration-300 group-aria-selected/cell:bg-primary-300 hover:bg-primary-300 motion-safe:transition-colors"
                        >
                          <Typography
                            text="editQuery"
                            variant="xs/semibold"
                            className="capitalize text-grey duration-300 group-aria-selected/cell:text-base-200 group-hover:text-base-200 motion-safe:transition-colors"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {selectedQuery ? <EditQueryModal query={selectedQuery} onClose={handleCloseQueryModal} /> : null}
      {selectedList ? <EditListModal userList={selectedList} onClose={handleCloseUserListModal} /> : null}
    </div>
  );
};

export default MyArea;
