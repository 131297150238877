import { useSearchFiltersStore } from '@global/searchFilters';
import { FC } from 'react';
import { FilterCard } from '../common';

export const FundingFilter: FC = () => {
  const funding = useSearchFiltersStore((state) => state.funding);
  const setFunding = useSearchFiltersStore((state) => state.actions.setFunding);

  return (
    <FilterCard label={{ text: 'funding_punctuation', interpolationsParams: { punctuation: '?' } }}>
      <div className="justify-left flex items-center gap-2">
        <span className="text-sm">No</span>
        <div className="form-control">
          <label className="label flex w-fit cursor-pointer gap-4">
            <input
              type="checkbox"
              className="toggle toggle-primary"
              checked={funding}
              onChange={(e) => setFunding(e.target.checked)}
              data-label="funding"
            />
          </label>
        </div>
        <span className="text-sm">Yes</span>
      </div>
    </FilterCard>
  );
};
